import React from 'react'

const Checkbox = ({ checked }) => {
   return (
      <div
         className={
            checked
               ? 'dropdown-list_item-checkbox-active'
               : 'dropdown-list_item-checkbox'
         }
      >
         {checked && (
            <svg
               width="10"
               height="10"
               viewBox="0 0 10 10"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.55447 2.69553C8.67651 2.81757 8.67651 3.01544 8.55447 3.13747L4.3878 7.30414C4.26576 7.42618 4.0679 7.42618 3.94586 7.30414L1.86253 5.22081C1.74049 5.09877 1.74049 4.90091 1.86253 4.77887C1.98456 4.65683 2.18243 4.65683 2.30447 4.77887L4.16683 6.64123L8.11253 2.69553C8.23456 2.57349 8.43243 2.57349 8.55447 2.69553Z"
                  fill="white"
               />
            </svg>
         )}
      </div>
   )
}

export default Checkbox