import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   channelsWithoutRelations: []
}

const channelsSlice = createSlice({
   name: 'channelsSlice',
   initialState,
   reducers: {
      fetchChannelsWithoutRelationsSuccess: (state, action) => {
         state.channelsWithoutRelations = [ ...action.payload ]
      }
   }
})

export const {
   fetchChannelsWithoutRelationsSuccess
} = channelsSlice.actions
export default channelsSlice.reducer