import React, { useState } from 'react'
import './addContact.css'
import { CSSTransition } from 'react-transition-group'
import WarningBlock from './WarningBlock'

const TagModal = ({ isVisible, title, text, element, accept, cancel }) => {
   const [tagField, setTagField] = useState('')
   const [fieldsNotFilled, setFieldsNotFilled] = useState(false)
   const [isNotEnoughSymbols, setIsNotEnoughSymbols] = useState(false)

   const tagHandler = () => {
      if (tagField === '') {
         setFieldsNotFilled(true)
         setIsNotEnoughSymbols(false)
         return
      } else {
         setFieldsNotFilled(false)
      }
      if (tagField.length < 4) {
         setIsNotEnoughSymbols(true)
         setFieldsNotFilled(false)
         return
      } else {
         setIsNotEnoughSymbols(false)
      }

      accept({ id: element.id || '', name: tagField })
      setFieldsNotFilled(false)
      setTagField('')
      cancel()
   }

   return (
      <CSSTransition
         in={isVisible}
         classNames="add-contact"
         timeout={500}
         mountOnEnter
         unmountOnExit
      >
         <div className="add-contact">
            <div
               className="add-contact-wrapper"
               onClick={(e) => e.stopPropagation()}
            >
               <div className="add-contact-header">
                  <div className="add-contact-header-text">{title}</div>
                  <div className="add-contact-close">
                     <button
                        className="add-contact-close-button"
                        onClick={() => {
                           cancel()
                           setFieldsNotFilled(false)
                           setIsNotEnoughSymbols(false)
                           setTagField('')
                        }}
                     >
                        <img src="/assets/controls/close.svg" alt="" />
                     </button>
                  </div>
               </div>

               <div className="add-contact-form-control">
                  <label
                     htmlFor=""
                     className={
                        fieldsNotFilled || isNotEnoughSymbols
                           ? 'add-contact-form-control-label-red'
                           : 'add-contact-form-control-label'
                     }
                  >
                     Название тега*
                  </label>
                  <input
                     type="text"
                     className={
                        fieldsNotFilled || isNotEnoughSymbols
                           ? 'add-contact-form-control-input-red'
                           : 'add-contact-form-control-input'
                     }
                     placeholder="Текст"
                     value={tagField}
                     maxLength="17"
                     minLength="4"
                     onChange={(e) => setTagField(e.target.value)}
                  />
               </div>
               {fieldsNotFilled && (
                  <WarningBlock
                     title={'Все поля обязательны для заполнения.'}
                  />
               )}
               {isNotEnoughSymbols && (
                  <WarningBlock title={'Введите более 3 символов.'} />
               )}
               <div className="add-contact-form-submit">
                  <button
                     className="add-contact-form-submit-button"
                     onClick={tagHandler}
                  >
                     {text}
                  </button>
               </div>
            </div>
         </div>
      </CSSTransition>
   )
}

export default TagModal
