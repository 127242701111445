import React, { useEffect } from 'react'

const Timer = ({ timer, setTimer }) => {
   useEffect(() => {
      var timerId = setInterval(() => {
         setTimer((prev) => prev + 1)
      }, 1000)

      return function cleanup() {
         clearInterval(timerId)
      }
   }, [setTimer])

   return (
      <>
         <span style={{ position: 'absolute', top: '-20px' }}>
            00:{timer < 10 ? '0' + timer : timer}
         </span>
      </>
   )
}

export default Timer
