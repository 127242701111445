import $axios from "../http"

class TemplatesService {
   static fetchTemplates = async (subsectionId) => {
      const { data } = await $axios.get('template/get' + (subsectionId ? `?subsectionId=${subsectionId}` : ''))
      return data
   }

   static createTemplate = async (template) => {
      const formData = new FormData()
      formData.append('name', template.name)
      formData.append('text', template.text)
      template.subsectionId && formData.append('subsectionId', template.subsectionId)
      template.file && formData.append('file', template.file)

      const { data } = await $axios.post('template/add', formData, { 
         'Content-Type': 'multipart/form-data' 
      })
      return data
   }

   static updateTemplate = async (template) => {
      const formData = new FormData()
      formData.append('name', template.name)
      formData.append('text', template.text)
      template.subsectionId && formData.append('subsectionId', template.subsectionId)
      template.file && formData.append('file', template.file)

      const { data } = await $axios.post(`template/update?id=${template.id}`, formData, {
         'Content-Type': 'multipart/form-data' 
      })
      return data
   }

   static deleteTemplate = async (id) => {
      const { data } = await $axios.delete(`template/delete?id=${id}`)
      return data
   }

   static fetchTemplateSubsections = async () => {
      const { data } = await $axios.get('template/get-subsections')
      return data
   }

   static createTemplateSubsection = async (subsectionName) => {
      const formData = new FormData()
      formData.append('name', subsectionName)

      const { data } = await $axios.post('template/add-subsection', formData, { 
         'Content-Type': 'multipart/form-data' 
      })
      return data
   }

   static updateTemplateSubsection = async (templateSubsection) => {
      const formData = new FormData()
      formData.append('name', templateSubsection.name)

      const { data } = await $axios.post(`template/update-subsection?subsectionId=${templateSubsection.id}`, formData, {
         'Content-Type': 'multipart/form-data' 
      })
      return data
   }

   static deleteTemplateSubsection = async (id) => {
      const { data } = await $axios.delete(`template/delete-subsection?subsectionId=${id}`)
      return data
   }
}

export default TemplatesService