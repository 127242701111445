import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import '../auth.css'
import './ForgotPasswordPage.css'

const ForgotPasswordPage = ({ onSubmit, onReturnBack }) => {
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

   const handleReturnBack = () => onReturnBack()

   const formik = useFormik({
      initialValues: {
         email: ''
      },
      validationSchema: yup.object().shape({
         email: yup.string()
            .matches(
               /^[^\s@]+@[^\s@.]+\.[^\s@.]+$/,
               'Пожалуйста, введите корректный email-адрес'
            )
           .required('Email-адрес обязателен для заполнения'),
      }),
      onSubmit: async (values, { setStatus }) => {
         try {
            setDisableSubmitBtn(true)
            await onSubmit(values)
         } catch (err) {
            setStatus(err)
         } finally {
            setDisableSubmitBtn(false)
         }
      }
   })

   return (
      <div className="auth">
         <div className="login-card forgot-password-page__card">
            <div className="login-card-wrapper forgot-password-page__wrapper">
               <div className="login-card-header-wrapper forgot-password-page__header-wrapper">
                  <h3
                     className="login-card-header-text forgot-password-page__header"
                  >
                     WEB MESSENGER
                  </h3>
               </div>
               <div className='forgot-password-page__description'>
                  <h4 className='forgot-password-page__description-header'>
                     Восстановление пароля
                  </h4>
                  <p className='forgot-password-page__description-text'>
                     Для восстановления пароля, введите свой Email, который вы указали при регистрации
                  </p>
               </div>
               <form 
                  className='forgot-password-form'
                  onSubmit={formik.handleSubmit}
               >
                  {formik.status && (<p className='forgot-password-form__status'>{formik.status}</p>)}
                  <div className='forgot-password-form-control'>
                     <label 
                        htmlFor="email"
                        className={`forgot-password-form-control__label ${formik.touched.email && formik.errors.email ? 'forgot-password-form-control__label_error' : ''}`}
                     >
                        {(formik.touched.email && formik.errors.email) || 'Email'}
                     </label>
                     <input
                        type="text"
                        className="login-card-credentials-field forgot-password-form-input"
                        id='email'
                        name='email'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="example@mail.com"
                        autoComplete="off"
                     />
                  </div>
                  <button
                     type='submit'
                     className='forgot-password-form__submit-btn'
                     disabled={disableSubmitBtn}
                  >
                     Восстановить
                  </button>
               </form>
               <button
                  type='button'
                  className='forgot-password-page__return-btn'
                  onClick={handleReturnBack}
               >
                  <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path fillRule="evenodd" clipRule="evenodd" d="M5.68705 0.146447C5.88231 0.341709 5.88231 0.658291 5.68705 0.853553L2.0406 4.5L5.68705 8.14645C5.88231 8.34171 5.88231 8.65829 5.68705 8.85355C5.49179 9.04882 5.1752 9.04882 4.97994 8.85355L0.979943 4.85355C0.784681 4.65829 0.784681 4.34171 0.979943 4.14645L4.97994 0.146447C5.1752 -0.0488155 5.49179 -0.0488155 5.68705 0.146447Z" fill="#40B7D9"/>
                  </svg>
                  Вернуться назад
               </button>
            </div>
         </div>
      </div>
   )
}

export default ForgotPasswordPage