import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   messagesLoader: false,
   messages: []
}

const messagesSlice = createSlice({
   name: 'messages',
   initialState,
   reducers: {
      setMessagesLoader: (state, action) => {
         state.messagesLoader = action.payload
      },
      setMessages: (state, action) => {
         state.messages = action.payload
      },
      sendMessageFail: (state, action) => {
         state.messages = [action.payload, ...state.messages]
      },
      fetchMessagesSuccess: (state, action) => {
         state.messages = [ ...state.messages, ...action.payload ]
      },
      updateMessagesOnNewMessage: (state, action) => {
         state.messages = [ action.payload, ...state.messages ]
      },
      updateMessagesOnMessageStatusEvent: (state, action) => {
         switch (Number(action.payload.status)) {
            case 4:
               state.messages = state.messages.map(message => (
                  message.id === action.payload.id
                     ? { 
                        ...message, 
                        text: "🚫 Сообщение удалено.",
                        status: +action.payload.status 
                     }
                     : message
               ))
               break
            default:
               state.messages = state.messages.map(message => (
                  message.id === action.payload.id
                     ? { ...message, status: +action.payload.status }
                     : message
               ))
         }
      },
      updateMessagesOnUpdateMessageEvent: (state, action) => {
         state.messages = state.messages.map(message => (
            message.id === action.payload.id
               ? { ...action.payload }
               : message
         ))
      },
      editMessageSuccess: (state, action) => {
         state.messages = state.messages.map(message => (
            message.id === action.payload.id
               ? action.payload
               : message
         ))
      }
   }
})

export const {
   setMessagesLoader,
   setMessages,
   fetchMessagesSuccess,
   updateMessagesOnNewMessage,
   updateMessagesOnMessageStatusEvent,
   updateMessagesOnUpdateMessageEvent,
   sendMessageFail,
   editMessageSuccess
} = messagesSlice.actions
export default messagesSlice.reducer