import React, { useEffect, useState } from 'react'
import './AttachmentsBox.css'

import FilesQty from './FilesQty'
import AttachmentsItem from './AttachmentsItem'

const AttachmentsBox = ({ files = [], disabled = false, onChange, onDelete }) => {
   const [showFilesList, setShowFilesList] = useState(false)

   const filesQty = files.length

   const handleChange = (e) => {
      onChange(e.target.files)
   }

   const handleToggleFilesList = () => setShowFilesList(show => !show)

   const handleCloseFilesList = () => setShowFilesList(false)

   const handleDeleteFile = (id) => {
      onDelete(id)
      if (files.length < 2) setShowFilesList(false)
   }

   useEffect(() => {
      if (files.length < 1) setShowFilesList(false)
   }, [files])

   return (
      <div className='attachments-box'>
         {filesQty < 1 
            ? (
               <label className="attachments-box__add-btn attachments-add-btn" for="add-files">
                  {filesQty > 0 && <FilesQty filesQty={filesQty} />}
                  <svg
                     width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill={filesQty >= 1 ? '#40B7D9' : '#252525'}
                     opacity={disabled ? '.1' : '.3'}
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.3587 4.95927C16.8232 3.49478 19.1976 3.4948 20.6621 4.95926L20.1328 5.4885L20.6621 4.95927C22.1265 6.42372 22.1265 8.79808 20.6621 10.2626L20.6621 10.2626L11.8232 19.1014C9.77296 21.1516 6.44884 21.1516 4.39859 19.1014L4.92892 18.571L4.39859 19.1014C2.34834 17.0511 2.34834 13.727 4.39859 11.6768L11.8232 4.25215C12.1161 3.95925 12.591 3.95925 12.8839 4.25215C13.1767 4.54504 13.1767 5.01991 12.8839 5.31281L5.45925 12.7374L4.92892 12.2071L5.45925 12.7374C3.99478 14.2019 3.99478 16.5763 5.45925 18.0407L4.93823 18.5617L5.45925 18.0407C6.92371 19.5052 9.29809 19.5052 10.7625 18.0407L11.2575 18.5357L10.7625 18.0407L19.6014 9.20193C19.6014 9.20192 19.6014 9.20192 19.6014 9.20192C20.4801 8.3232 20.48 6.89857 19.6014 6.01993C18.7227 5.14123 17.2981 5.14124 16.4194 6.01992L16.4194 6.01993L7.58058 14.8587C7.28769 15.1516 7.28769 15.6265 7.58058 15.9194C7.87349 16.2123 8.34835 16.2123 8.64124 15.9194L16.0658 8.49477C16.3587 8.20187 16.8336 8.20187 17.1265 8.49477C17.4194 8.78766 17.4194 9.26253 17.1265 9.55543L9.70191 16.9801C8.82321 17.8588 7.39859 17.8588 6.5199 16.9801L6.51987 16.9801C5.64126 16.1014 5.64126 14.6768 6.51987 13.7981L6.5199 13.7981L15.3587 4.95928C15.3587 4.95927 15.3587 4.95927 15.3587 4.95927Z"
                     />
                  </svg>
                  <input
                     className='attachments-add-btn__input'
                     type="file"
                     name=""
                     id="add-files"
                     onChange={handleChange}
                     disabled={disabled}
                     multiple
                  />
               </label>
            ) : (
               <button 
                  className='attachments-box__add-btn attachments-show-popup-btn' 
                  onClick={handleToggleFilesList}
               >
                  {filesQty > 0 && <FilesQty filesQty={filesQty} />}
                  <svg
                     width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill={filesQty >= 1 ? '#40B7D9' : '#252525'}
                     opacity={disabled ? '.1' : '.3'}
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.3587 4.95927C16.8232 3.49478 19.1976 3.4948 20.6621 4.95926L20.1328 5.4885L20.6621 4.95927C22.1265 6.42372 22.1265 8.79808 20.6621 10.2626L20.6621 10.2626L11.8232 19.1014C9.77296 21.1516 6.44884 21.1516 4.39859 19.1014L4.92892 18.571L4.39859 19.1014C2.34834 17.0511 2.34834 13.727 4.39859 11.6768L11.8232 4.25215C12.1161 3.95925 12.591 3.95925 12.8839 4.25215C13.1767 4.54504 13.1767 5.01991 12.8839 5.31281L5.45925 12.7374L4.92892 12.2071L5.45925 12.7374C3.99478 14.2019 3.99478 16.5763 5.45925 18.0407L4.93823 18.5617L5.45925 18.0407C6.92371 19.5052 9.29809 19.5052 10.7625 18.0407L11.2575 18.5357L10.7625 18.0407L19.6014 9.20193C19.6014 9.20192 19.6014 9.20192 19.6014 9.20192C20.4801 8.3232 20.48 6.89857 19.6014 6.01993C18.7227 5.14123 17.2981 5.14124 16.4194 6.01992L16.4194 6.01993L7.58058 14.8587C7.28769 15.1516 7.28769 15.6265 7.58058 15.9194C7.87349 16.2123 8.34835 16.2123 8.64124 15.9194L16.0658 8.49477C16.3587 8.20187 16.8336 8.20187 17.1265 8.49477C17.4194 8.78766 17.4194 9.26253 17.1265 9.55543L9.70191 16.9801C8.82321 17.8588 7.39859 17.8588 6.5199 16.9801L6.51987 16.9801C5.64126 16.1014 5.64126 14.6768 6.51987 13.7981L6.5199 13.7981L15.3587 4.95928C15.3587 4.95927 15.3587 4.95927 15.3587 4.95927Z"
                     />
                  </svg>
               </button>
         )}
         <div className={`attachments-box__popup attachments-popup ${showFilesList ? 'attachments-popup_active' : ''}`}>
            <ul className='attachments-popup__list'>
               {files.map(file => (
                  <AttachmentsItem 
                     key={file.id}
                     file={file} 
                     onDelete={handleDeleteFile} />
               ))}
            </ul>
            <div className='attachments-popup__actions'>
               <label className='attachments-popup__add-btn attachments-add-more-btn' for="add-more-files" >
                  <span className='attachments-add-more-btn__text'>Добавить</span>
                  <input
                     className='attachments-add-more-btn__input'
                     type="file"
                     name=""
                     id="add-more-files"
                     onChange={handleChange}
                     disabled={disabled}
                     multiple
                  />
               </label>
               <button
                  className='attachments-popup__close-btn' 
                  onClick={handleCloseFilesList}
               >
                  Закрыть
               </button>
            </div>
         </div>
      </div>
   )
}

export default AttachmentsBox