import './CustomTooltip.css'

const CustomTooltip = ({
   index,
   isLastStep,
   step,
   backProps,
   skipProps,
   primaryProps,
   tooltipProps,
}) => {
   return (
      <div {...tooltipProps} className='onboarding-tooltip'>
         <div className='onboarding-tooltip__body'>
            <div className='onboarding-tooltip__content'>
               <h4 className='onboarding-tooltip__title'>{step.title}</h4>
               <p className='onboarding-tooltip__text'>{step.content}</p>
            </div>
            <div className='onboarding-tooltip__footer'>
               <button {...skipProps} className='onboaring-tooltip__btn onboaring-btn-skip'>
                  Пропустить
               </button>
               {index > 0 && (
                  <button {...backProps} className='onboaring-tooltip__btn onboaring-btn-back'>
                     Назад
                  </button>
               )}
               <button {...primaryProps} className='onboaring-tooltip__btn onboaring-btn-next'>
                  {isLastStep ? 'Завершить' : 'Далее'}
               </button>
            </div>
            <button {...skipProps} className='onboaring-btn-close'>
               <span  className='onboaring-btn-close__body'></span>
            </button>
         </div>
      </div>
   )
}

export default CustomTooltip