import React from 'react'
import './sidebarList.css'
import { Link } from 'react-router-dom'

const SidebarItem = ({ item, toggle, mark }) => {
   const buttonHandler = () => {
      mark(item.position)
      toggle(item.position)
   }
   return (
      <Link to={item.path} style={{ textDecoration: 'none', color: '#000000' }}>
         <div
            className={
               item.isChecked
                  ? 'sidebar-bot-settings-card-active'
                  : 'sidebar-bot-settings-card'
            }
            onClick={buttonHandler}
         >
            <div className="sidebar-bot-settings-card_block">
               <img src={item.icon} alt="" />
               <span>{item.name}</span>
            </div>
            <div className="sidebar-bot-settings-card_image">
               <img
                  src="/assets/settings/right.svg"
                  style={{ width: '25px' }}
                  alt=""
               />
            </div>
         </div>
      </Link>
   )
}

export default SidebarItem
