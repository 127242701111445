const tooltipsData = {
   navigation: {
      dialogs: 'Диалоги',
      contacts: 'Контакты',
      managers: 'Менеджеры',
      mailing: 'Рассылки',
      settings: 'Настройки',
      logout: 'Выйти'
   },
   chat: {
      statusList: 'Доступные статусы',
      tagList: 'Доступные теги',
      replyBtn: 'Ответить',
      templates: 'Шаблоны'
   }
}

export default tooltipsData