import { useEffect, useRef } from 'react'

const useClickOutside = (callback = () => {}) => {
   const ref = useRef()

   const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
         callback()
      }
   }

   useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('touchstart', handleClickOutside)

      return () => {
         document.removeEventListener('mousedown', handleClickOutside)
         document.removeEventListener('touchstart', handleClickOutside)
      }
   }, [])

   return ref
};

export default useClickOutside