export const settings = [
   {
      name: 'Чат-боты',
      path: '/chat-bots',
      position: 1,
      isChecked: false,
      icon: '/assets/settings/chat_bot.svg',
      onlyForAdmin: true,
   },
   {
      name: 'Шаблоны',
      path: '/template',
      position: 2,
      isChecked: false,
      icon: '/assets/settings/template.svg',
      onlyForAdmin: false,
   },
   {
      name: 'Теги и статусы',
      path: '/tags-statuses',
      position: 3,
      isChecked: false,
      icon: '/assets/settings/tag.svg',
      onlyForAdmin: true,
   },
   {
      name: 'Настройки диалога',
      path: '/dialog-settings',
      position: 4,
      isChecked: false,
      icon: '/assets/settings/setting_dialog.svg',
      onlyForAdmin: false,
   },
]
