const getCurrentPortionOfItems = (items, firstIndex, lastIndex) => items.slice(
   firstIndex,
   lastIndex
)

const getPageNumbers = (items, qtyPerPage) => {
   const numbers = []
   for (let i = 1; i <= Math.ceil(items.length / qtyPerPage); i++) {
      numbers.push(i)
   }
   return numbers
}

export {
   getCurrentPortionOfItems,
   getPageNumbers
}