import { useState, useRef, useEffect } from 'react'

const useAutoResizeTextarea = (defaultValue = '') => {
   const [value, setValue] = useState(defaultValue)
   const inputRef = useRef(null)

   useEffect(() => setValue(defaultValue), [defaultValue])

   useEffect(() => {
      if (inputRef && inputRef.current) {
         inputRef.current.style.height = "0px"
         const scrollHeight = inputRef.current.scrollHeight
         inputRef.current.style.height = scrollHeight + "px"
      }
   }, [value])
   
   return {
      value,
      setValue,
      inputRef
   }
}

export default useAutoResizeTextarea