import React, { useState, useEffect } from 'react'
import './ToggleWithText.css'

const ToggleWithText = ({ checked, text = '', onChange }) => {
   const [isChecked, setIsChecked] = useState(checked)

   const handleChange = () => {
      setIsChecked(!isChecked)
      onChange(!isChecked)
   }

   useEffect(() => setIsChecked(checked), [checked])

   return (
      <div className='toggle-with-text'>
         <label className='toggle-with-text__container'>
            <div className={`toggle-with-text__toggle ${checked ? 'toggle-with-text__toggle_active' : 'toggle-with-text__toggle_inactive'}`}>
               <input 
                  className='toggle-with-text__input' 
                  type="checkbox" 
                  checked={isChecked}
                  onChange={handleChange}
               />
            </div>
         </label>
         <p className='toggle-with-text__text'>{text}</p>
      </div>
   )
}

export default ToggleWithText