import { useEffect, useState } from "react"

const useCheckIsPageInFocus = () => {
   const [pageIsInFocus, setPageIsInFocus] = useState(false)

   const handleTogglePageFocus = () => {
      setPageIsInFocus(isInFocus => !isInFocus)
   }

   useEffect(() => {
      window.addEventListener("focus", handleTogglePageFocus)
      window.addEventListener("blur", handleTogglePageFocus)

      handleTogglePageFocus()

      return () => {
         window.removeEventListener("focus", handleTogglePageFocus)
         window.removeEventListener("blur", handleTogglePageFocus)
      }
   }, [])

   return [pageIsInFocus]
}

export default useCheckIsPageInFocus