import $axios from '../http'

class MessagesService {
   static fetchMessages = async (query = '') => {      
      const { data } = await $axios.get('message/get' + query)
      return data
   }

   static toggleMessageStatus = async (message) => {
      const formData = new FormData()
      formData.append('status', message.status === 1 ? 3 : 1)
      formData.append('external_id', [message.external_id])

      const { data } = await $axios.post('message/status', formData, { 
         'Content-Type': 'multipart/form-data' 
      })
      return data
   }

   static readMessages = async (unreadMessagesIds) => {
      const formData = new FormData()
      formData.append('status', 3)
      formData.append('external_id', unreadMessagesIds)

      const { data } = await $axios.post('message/status', formData, { 
         'Content-Type': 'multipart/form-data' 
      })
      return data
   }

   static readDialog = async (dialogId) => {
      const formData = new FormData()
      formData.append('dialog_id', dialogId)
      formData.append('status', 3)

      const { data } = await $axios.post('message/status', formData, { 
         'Content-Type': 'multipart/form-data' 
      })
      return data
   }

   static sendAudio = async (body) => {
      const { data } = await $axios.post('message/audio', body, {
         headers: { 'Content-Type': 'multipart/form-data' }
      })
      return data
   }

   static outgoing = async (body) => {
      const { data } = await $axios.post('message/outgoing', body, {
         headers: { 'Content-Type': 'multipart/form-data' }
      })
      return data
   }

   static editMessage = async (body) => {
      const { data } = await $axios.post('message/edit', body)
      return data
   }

   static forwardMessages = async (body) => {
      const { data } = await $axios.post('message/forward', body)
      return data
   }

   static revokeMessage = async (body) => {
      const { data } = await $axios.post('message/revoke', body)
      return data
   }
}

export default MessagesService