import React, { useState } from 'react'

import './mailing.css'

import useClickOutside from '../../hooks/useClickOutside'
import { mailingTypesMap } from '../../utils/consts'

const MailingTypeSelect = ({ onSelect }) => {
   const [isActive, setIsActive] = useState(false)

   const mailingTypes = Object.values(mailingTypesMap)

   const handleMailingTypeSelectToggle = () => setIsActive(active => !active)

   const handleMailingTypeSelectClose = () => setIsActive(false)

   const handleSelect = (type) => {
      onSelect(type)
      handleMailingTypeSelectClose()
   }

   const mailingTypeSelectRef = useClickOutside(handleMailingTypeSelectClose)

   return (
      <div ref={mailingTypeSelectRef} className={`mailing-type-select ${isActive ? 'active' : ''}`}>
         <button 
            className='mailing-type-select__btn'
            onClick={handleMailingTypeSelectToggle}   
         >
            Создать рассылку
            <svg className='mailing-type-select__btn-icon' width="11" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fill-rule="evenodd" clip-rule="evenodd" d="M4.47994 0.478478C4.67521 0.283216 4.99179 0.283216 5.18705 0.478478L9.18705 4.47848C9.38231 4.67374 9.38231 4.99032 9.18705 5.18558C8.99179 5.38085 8.67521 5.38085 8.47994 5.18558L4.8335 1.53914L1.18705 5.18558C0.991787 5.38085 0.675205 5.38085 0.479943 5.18558C0.284681 4.99032 0.284681 4.67374 0.479943 4.47848L4.47994 0.478478Z" fill="#252525"/>
            </svg>
         </button>
         <ul className='mailing-type-select__list'>
            {mailingTypes.map(type => (
               <li 
                  key={type}
                  className='mailing-type-select__list-item'
                  onClick={() => handleSelect(type)}
               >
                  {type}
               </li>
            ))}
         </ul>
      </div>
   )
}

export default MailingTypeSelect