import './managerMainbar.css'
import PaginationItem from '../settings/PaginationItem'
import React, { useState } from 'react'
import ManagerPermissionItem from './ManagerPermissionItem'
import { useSelector } from 'react-redux'
import { channels_selectChannelsWithoutRelations } from '../../store/slices/channels/selectors'
import { dialogSteps_selectDialogStepsWithoutRelations } from '../../store/slices/dialogSteps/selectors'
import { tags_selectTagsWithoutRelations } from '../../store/slices/tags/selectors'
const ManagerPermissionList = ({
   title,
   elements,
   permissions,
   addPermission,
   deletePermission,
   isAllAllow,
   toggleAllPermissions,
}) => {
   const channelsWithoutRelations = useSelector(channels_selectChannelsWithoutRelations)
   const dialogStepsWithoutRelations = useSelector(dialogSteps_selectDialogStepsWithoutRelations)
   const tagsWithoutRelations = useSelector(tags_selectTagsWithoutRelations)

   const [currentPage, setCurrentPage] = useState(1)
   const [elementsPerPage, setElementsPerPage] = useState(7)
   const lastElementIndex = currentPage * elementsPerPage
   const firstElementIndex = lastElementIndex - elementsPerPage
   const currentElements = elements.slice(firstElementIndex, lastElementIndex)

   const pageNumbers = []

   for (let i = 1; i <= Math.ceil(elements.length / elementsPerPage); i++) {
      pageNumbers.push(i)
   }

   const [activePermissions, setActivePermissions] = useState([])

   const getEntityIsNotAttached = (entity) => {
      let entityList
      switch (title) {
         case 'Каналы':
            entityList = channelsWithoutRelations
            break
         case 'Статусы':
            entityList = dialogStepsWithoutRelations
            break
         default:
            entityList = tagsWithoutRelations
      }
      return entityList.find(el => el.id === entity.id) !== undefined
   }

   const activeToggleButton = {
      backgroundColor: 'white',
      marginLeft: 'auto',
      marginRight: '0'
   }

   const inActiveToggleButton = {
      backgroundColor: 'white',
      marginRight: 'auto',
   }

   return (
      <div className={'manager-permission-control_list'}>
         <span className="manager-permission-control-field-name">{title}</span>
         <ul className="manager-permission_list">
            <div>
               <div className="manager-permission-control-field">
                  <span className="manager-permission-field-name">Все</span>
                  <div
                     className={
                        isAllAllow
                           ? 'manager-permission-controller-active'
                           : 'manager-permission-controller'
                     }
                     onClick={toggleAllPermissions}
                  >
                     <div
                        className="manager-permission-toggle-button"
                        style={
                           isAllAllow
                              ? activeToggleButton
                              : inActiveToggleButton
                        }
                     ></div>
                  </div>
               </div>
            </div>
            {currentElements.map((element) => {
               const entityIsNotAttached = getEntityIsNotAttached(element)

               return (
                  <ManagerPermissionItem
                     isAllAllow={isAllAllow}
                     entityIsNotAttached={entityIsNotAttached}
                     toggleAllPermissions={toggleAllPermissions}
                     activeToggleButton={activeToggleButton}
                     inActiveToggleButton={inActiveToggleButton}
                     permissions={permissions}
                     activePermissions={activePermissions}
                     setActivePermissions={setActivePermissions}
                     key={element.id}
                     element={element}
                     addPermission={addPermission}
                     deletePermission={deletePermission}
                  />
               )
            })}
         </ul>
         {elements.length > elementsPerPage && (
            <div className="manager-permission-pagination">
               <PaginationItem
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageNumbers={pageNumbers}
               />
            </div>
         )}
      </div>
   )
}
export default ManagerPermissionList
