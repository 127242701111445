export const DEFAULT_LIMIT = '20'

export const DEFAULT_DIALOGS_FILTER = {
   limit: DEFAULT_LIMIT,
   searchValue: '',
   unreadOnly: false,
   next_cursor: null
}

export const dateStatusNamesMap = {
   overdue: 'Просрочено',
   today: 'Сегодня',
   future: 'Предстоящая'
}