import React from 'react'
import './FormTooltip.css'

const FormTooltip = ({ text = 'Подсказка', error }) => {
   return (
      <div className={`form-tooltip ${error ? 'form-tooltip-error' : ''}`}>
         <span>?</span>
         <div className='form-tooltip-body'>
            {text}
         </div>
      </div>
   )
}

export default FormTooltip