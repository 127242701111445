import React, { useRef, useState } from 'react'
import './ImageWithoutTouch.css'

const ImageWithoutTouch = ({ 
   src = '', 
   alt = '', 
   className = '',
   isTouchDevice,
   pressToAvoidAction = 300,
   onError = () => {},
   onClick = () => {}
}) => {
   const [isRightClick, setIsRightClick] = useState(false)
   const pressStartTime = useRef(null)

   const handleMouseDown = (e) => {
      pressStartTime.current = Date.now()
      setIsRightClick(e.button === 2 ? true : false)
   }

   const handleMouseUp = () => {
      if (isRightClick) return

      const pressEndTime = Date.now()
      const pressDuration = pressEndTime - pressStartTime.current

      if (pressDuration < pressToAvoidAction || !isTouchDevice) onClick()
   }

   const handleLoadingError = (e) => {
      onError(e)
   }

   return (
      <div 
         className={`image-without-touch ${className}`}
         onMouseDown={handleMouseDown}
         onMouseUp={handleMouseUp}
      >
         <img 
            className='image-without-touch__img'
            src={src} 
            alt={alt}
            onError={handleLoadingError}
         />
         <div className='image-without-touch__overlay'></div>
      </div>
   )
}

export default ImageWithoutTouch