import DialogStepsService from "../../../services/dialogStepsService"
import { 
   createDialogStepSuccess, 
   deleteDialogStepSuccess, 
   fetchDialogStepsFinished, 
   fetchDialogStepsStarted, 
   fetchDialogStepsSuccess, 
   fetchDialogStepsWithoutRelationsSuccess, 
   updateDialogStepSuccess
} from "."

export const fetchDialogStepsWithoutRelations = () => async (dispatch) => {
   try {
      const data = await DialogStepsService.checkDialogStepRelations()

      if (!data.error) {
         dispatch(fetchDialogStepsWithoutRelationsSuccess(data.data ? data.data : []))
      }

      return data.data
   } catch (err) {
      console.log(err)
   }
}

export const fetchDialogSteps = () => async (dispatch) => {
   try {
      dispatch(fetchDialogStepsStarted())
      const data = await DialogStepsService.fetchDialogSteps()

      if (!data.error) {
         dispatch(fetchDialogStepsSuccess(data.data))
      }
   } catch (err) {
      console.log(err)
   } finally {
      dispatch(fetchDialogStepsFinished())
   }
}

export const createDialogStep = (dialogStep) => async (dispatch) => {
   try {
      const data = await DialogStepsService.createDialogStep(dialogStep)

      if (!data.error) {
         dispatch(createDialogStepSuccess(data.data))
      }
   } catch (err) {
      console.log(err)
   }
}

export const updateDialogStep = (dialogStep) => async (dispatch) => {
   try {
      const data = await DialogStepsService.updateDialogStep(dialogStep)

      if (!data.error) {
         dispatch(updateDialogStepSuccess(dialogStep))
      }
   } catch (err) {
      console.log(err)
   }
}

export const deleteDialogStep = (id) => async (dispatch) => {
   try {
      const data = await DialogStepsService.deleteDialogStep(id)

      if (!data.error) {
         dispatch(deleteDialogStepSuccess(id))
      }
   } catch (err) {
      console.log(err)
   }
}