import ChannelsService from "../../../services/channelsService"
import { fetchChannelsWithoutRelationsSuccess } from "."

export const fetchChannelsWithoutRelations = () => async (dispatch) => {
   try {
      const data = await ChannelsService.checkChannelRelations()

      if (!data.error) {
         dispatch(fetchChannelsWithoutRelationsSuccess(data.data ? data.data : []))
      }

      return data.data
   } catch (err) {
      console.log(err)
   }
}