import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   templatesLoading: false,
   templates: [],
   templateSubsectionsLoading: false,
   templateSubsections: []
}

const templatesSlice = createSlice({
   name: 'templates',
   initialState,
   reducers: {
      fetchTemplatesStarted: (state) => {
         state.templatesLoading = true
      },
      fetchTemplatesSuccess: (state, action) => {
         state.templates = [ ...state.templates, ...action.payload ]
         state.templates = [ 
            ...state.templates.sort((a, b) => {
               if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1
               }
               return 1
         })]
      },
      fetchTemplatesFinished: (state) => {
         state.templatesLoading = false
      },
      createTemplateSuccess: (state, action) => {
         state.templates = [ ...state.templates, action.payload ]
         state.templates = [ 
            ...state.templates.sort((a, b) => {
               if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1
               }
               return 1
         })]
      },
      updateTemplateSuccess: (state, action) => {
         state.templates = state.templates.map(template => template.id === action.payload.id
            ? { ...template, ...action.payload }
            : template
         )
      },
      deleteTemplateSuccess: (state, action) => {
         state.templates = state.templates.filter(template => template.id !== action.payload)
      },
      fetchTemplateSubsectionsStarted: (state) => {
         state.templateSubsectionsLoading = true
      },
      fetchTemplateSubsectionsSuccess: (state, action) => {
         state.templateSubsections = [ ...state.templateSubsections, ...action.payload ]
         state.templateSubsections = [ 
            ...state.templateSubsections.sort((a, b) => {
               if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1
               }
               return 1
         })]
      },
      fetchTemplateSubsectionsFinished: (state) => {
         state.templateSubsectionsLoading = false
      },
      createTemplateSubsectionSuccess: (state, action) => {
         state.templateSubsections = [ ...state.templateSubsections, action.payload ]
         state.templateSubsections = [ 
            ...state.templateSubsections.sort((a, b) => {
               if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1
               }
               return 1
         })]
      },
      updateTemplateSubsectionSuccess: (state, action) => {
         state.templateSubsections = state.templateSubsections.map(templateSubsection => templateSubsection.subsection_id === action.payload.subsection_id
            ? { ...templateSubsection, ...action.payload }
            : templateSubsection
         )
      },
      deleteTemplateSubsectionSuccess: (state, action) => {
         state.templateSubsections = state.templateSubsections.filter(templateSubsection => templateSubsection.subsection_id !== action.payload)
      }
   }
})

export const {
   fetchTemplatesStarted,
   fetchTemplatesSuccess,
   fetchTemplatesFinished,
   createTemplateSuccess,
   updateTemplateSuccess,
   deleteTemplateSuccess,
   fetchTemplateSubsectionsStarted,
   fetchTemplateSubsectionsSuccess,
   fetchTemplateSubsectionsFinished,
   createTemplateSubsectionSuccess,
   updateTemplateSubsectionSuccess,
   deleteTemplateSubsectionSuccess
} = templatesSlice.actions
export default templatesSlice.reducer