import React, { useEffect, useMemo, useState } from 'react'
import ErrorContent from '../components/auth/ErrorContent'
import './auth.css'

const ManagerAuth = ({
   handleToggle,
   isManager,
   isAmoLogin,
   authError,
   showResetPasswordSuccessNotification,
   onLogin,
   onForgotPassword
}) => {
   const [managerLogin, setManagerLogin] = useState('')
   const [managerPassword, setManagerPassword] = useState('')
   const [showPassword, setShowPassword] = useState(false)
   const [disableLoginBtn, setDisableLoginBtn] = useState(false)
   const [formStatus, setFormStatus] = useState('')
   
   const handleManagerLogin = async () => {
      setDisableLoginBtn(true)
      var bodyFormData = new FormData()
      bodyFormData.append('email', managerLogin)
      bodyFormData.append('password', managerPassword)
      const error = await onLogin(bodyFormData)
      setFormStatus(error ? error : '')
      setDisableLoginBtn(false)
   }

   const handleForgotPasswordClick = () => onForgotPassword()

   const errorDefault = 'default_error'

   const appHost = new URL(process.env.REACT_APP_AUTH_LINK).origin

   const errorData = useMemo(
      () => ({
         user_not_found: {
            head: (
               <>
                  Вы не подключили сервис <br /> интеграции i2crm
               </>
            ),
            message: (
               <>
                  Для подключения и настройки сервиса <br /> перейдите по{' '}
                  <a className="amo-login-error-link" href={appHost}>
                     ссылке
                  </a>
               </>
            ),
         },
         manager_not_found_error: {
            head: 'Не найден аккаунт менеджера',
            message: 'Обратитесь к администратору аккаунта.',
         },
         default_error: {
            head: 'Внутренняя ошибка.',
            message: 'Произошла непредвиденная ошибка, попробуйте позже.',
         },
      }),
      []
   )

   const renderError = (key) => {
      let errorKey = !!errorData[key] ? key : errorDefault
      return (
         <ErrorContent
            head={errorData[errorKey].head}
            message={errorData[errorKey].message}
         />
      )
   }

   return (
      <div className="auth">
         <div className="login-card">
            <div
               className="login-card-wrapper"
               style={{ paddingLeft: '56px', paddingRight: '56px' }}
            >
               <div
                  className="login-card-header-wrapper"
                  style={{ paddingBottom: '0px', marginBottom: '6px' }}
               >
                  <h1
                     className="login-card-header-title"
                     style={{
                        textAlign: 'center',
                        textTransform: 'capitalize',
                        paddingBottom: '0',
                     }}
                  >
                     WEB MESSENGER
                  </h1>
                  {authError ? (
                     ''
                  ) : (
                     <h3
                        className="login-card-header-text"
                        style={{
                           textAlign: 'center',
                           textTransform: 'capitalize',
                           paddingBottom: '0',
                        }}
                     >
                        Авторизация
                     </h3>
                  )}
               </div>
               {authError ? (
                  renderError(authError)
               ) : (
                  <div className="login-form-wrapper">
                     <div
                        className="login-card-body-wrapper"
                        style={{
                           display: 'flex',
                           flexDirection: 'column',
                           alignItems: 'center',
                           position: 'relative',
                        }}
                     >
                        {formStatus && (
                           <p className='login-form__status'>
                              {formStatus}
                           </p>
                        )}
                        <input
                           autoComplete="off"
                           type="text"
                           className="login-card-credentials-field"
                           placeholder="Почта"
                           style={{ marginBottom: '10px', paddingLeft: '14px' }}
                           value={managerLogin}
                           onChange={(e) =>
                              setManagerLogin(e.target.value.trim())
                           }
                        />
                        <input
                           autoComplete={showPassword ? 'text' : 'new-password'}
                           type={showPassword ? 'text' : 'password'}
                           className="login-card-credentials-field"
                           placeholder="Пароль"
                           style={{ paddingLeft: '14px' }}
                           value={managerPassword}
                           onChange={(e) => setManagerPassword(e.target.value)}
                        />
                        <img
                           src={`/assets/controls/password-${showPassword ? 'visible' : 'hidden'}-icon.svg`}
                           alt=""
                           onClick={() => setShowPassword((prev) => !prev)}
                           style={{
                              position: 'absolute',
                              right: '12px',
                              bottom: '12px',
                              cursor: 'pointer',
                           }}
                        />
                     </div>
                     <div
                        className="login-card-button-wrapper"
                        style={{ padding: '0px', paddingTop: '24px' }}
                     >
                        {!isAmoLogin ? (
                           <button
                              className="manager-login-card-reject"
                              onClick={handleToggle}
                           >
                              Отменить
                           </button>
                        ) : (
                           <div />
                        )}
                        <button
                           disabled={disableLoginBtn}
                           className="manager-login-card-signin"
                           onClick={handleManagerLogin}
                        >
                           Войти
                        </button>
                     </div>
                     <div className='login-card-forgot-password'>
                        <button 
                           className='login-card-forgot-password__btn'
                           onClick={handleForgotPasswordClick}   
                        >
                           Забыли пароль?
                        </button>
                     </div>
                  </div>
               )}
            </div>
         </div>
         {showResetPasswordSuccessNotification && (
            <div className='manager-login-notification'>
               <div className='manager-login-notification__body'>
                  <div className='manager-login-notification__icon'>
                     <img src="/assets/controls/notification-password.svg" alt="" />
                  </div>
                  <div className='manager-login-notification__text'>
                     Пароль успешно изменен!
                  </div>
               </div>
            </div>
         )}
      </div>
   )
}

export default ManagerAuth
