import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   cursor: null,
   filter: ''
}

const messagesFilterSlice = createSlice({
   name: 'messagesFilter',
   initialState,
   reducers: {
      setMessagesFilterCursor: (state, action) => {
         state.cursor = action.payload
      },
      setMessagesFilterFilter: (state, action) => {
         state.filter = action.payload
      },
      setMessagesFilter: (state, action) => {
         return {
            ...state,
            ...action.payload
         }
      }
   }
})

export const {
   setMessagesFilterCursor,
   setMessagesFilterFilter,
   setMessagesFilter
} = messagesFilterSlice.actions
export default messagesFilterSlice.reducer