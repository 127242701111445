import { useState, useEffect } from "react"
import useCheckIsPageInFocus from "./useCheckIsPageInFocus"

const useDisplayNotificationsQtyInTitle = () => {
   const [pageIsInFocus] = useCheckIsPageInFocus()
   const [uncheckedNotificationsDialogsIds, setUncheckedNotificationsDialogsIds] = useState([])

   const handleGettingNewNotification = (newMessageDialogId) => {
      if (pageIsInFocus) return 

      setUncheckedNotificationsDialogsIds(ids => {
         if (!ids.find(id => id === newMessageDialogId)) {
            return [...ids, newMessageDialogId]
         } else {
            return ids
         }
      })
   }

   useEffect(() => {
      if (!pageIsInFocus && uncheckedNotificationsDialogsIds.length) {
         document.title = `${uncheckedNotificationsDialogsIds.length} - уведомлений о новых сообщениях`
      } else if (pageIsInFocus) {
         document.title = `Веб-мессенджер`
         setUncheckedNotificationsDialogsIds([])
      }
   }, [pageIsInFocus, uncheckedNotificationsDialogsIds.length])

   return [handleGettingNewNotification]
}

export default useDisplayNotificationsQtyInTitle