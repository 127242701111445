import { CSSTransition } from 'react-transition-group'
import './mailingFileNotification.css'
import React from 'react'
const MailingFileNotification = ({
   isVisible,
   title,
   file,
   text,
   yes,
   button,
}) => {
   return (
      <CSSTransition
         in={isVisible}
         classNames="warning"
         timeout={500}
         mountOnEnter
         unmountOnExit
      >
         <div className="warning">
            <div className="warning_wrapper">
               <span className="warning_title">{title}</span>
               <p className="mailing-notification-file">{file}</p>
               <p className="warning_text">{text}</p>
               <div className="warning_buttons">
                  <button className="mailing-notification-button" onClick={yes}>
                     {button}
                  </button>
               </div>
            </div>
         </div>
      </CSSTransition>
   )
}

export default MailingFileNotification
