import $axios from "../http"

class DialogStepsService {
   static checkDialogStepRelations = async () => {
      const { data } = await $axios.get('dialog-step/check-dialog-step-relations')
      return data
   }

   static fetchDialogSteps = async () => {
      const { data } = await $axios.get('dialog-step/get')
      return data
   }

   static createDialogStep = async (dialogStep) => {
      const formData = new FormData()
      formData.append('name', dialogStep.name)
      formData.append('color', dialogStep.color)

      const { data } = await $axios.post('dialog-step/add', formData, { 
         'Content-Type': 'multipart/form-data' 
      })
      return data
   }

   static updateDialogStep = async (dialogStep) => {
      const formData = new FormData()
      formData.append('id', dialogStep.id)
      formData.append('name', dialogStep.name)
      formData.append('color', dialogStep.color)

      const { data } = await $axios.post('dialog-step/update', formData, { 
         'Content-Type': 'multipart/form-data' 
      })
      return data
   }

   static deleteDialogStep = async (id) => {
      const { data } = await $axios.delete(`dialog-step/delete?id=${id}`)
      return data
   }
}

export default DialogStepsService