import React from 'react'
import './DialogSystemMessage.css'

const DialogSystemMessage = () => {
   return (
      <div className="mainbar-conversations-system-message">
         <p>
            i2crm гарантирует полную безопасность вашего общения. Мы не
            храним Ваши пароли. Все сообщения защищены протоколом шифрования.
         </p>
      </div>
   )
}

export default DialogSystemMessage