import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import './draganddrop.css'
import store from '../../store'

function DragAndDrop({
   selectedConversation,
   setMessages,
   onDragCancel,
   onDragLeave,
   onDrop
}) {
   const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop })

   const handleChange = async () => {
      const filesUploaded = acceptedFiles
      let currentDate = new Date()

      filesUploaded.forEach(file => {
         if (file.size > 99 * 1024 * 1024) {
            alert('Файл не может превышать 100MB в размере!')
            return
         }
      })

      var bodyFormData = new FormData()
      bodyFormData.append('dialog_id', selectedConversation)
      filesUploaded.forEach(file => bodyFormData.append('files[]', file))

      try {
         const { data } = await axios({
            method: 'post',
            url: `/api/v1/message/outgoing?`,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
         })
         const messages = store.getState().messages.messages

         if (data.error) {
            const sendedMessage = {
               id: selectedConversation,
               text: data.data.error,
               timestamp: currentDate,
               isDocument: false,
               isIncoming: false,
               status: -1,
               type: 'text',
            }
            setMessages([sendedMessage, ...messages])
         }
      } catch (err) {
         console.log(err)
      }
   }

   useEffect(() => {
      if (acceptedFiles.length !== 0) {
         handleChange()
      }
   }, [acceptedFiles])

   useEffect(() => {
      window.addEventListener('keyup', onDragCancel)
      return () => {
         window.addEventListener('keyup', onDragCancel)
      }
   }, [])

   return (
      <section
         className="container"
         style={{
            filter: 'opacity(0)',
            height: '100vh',
            zIndex: '999',
            width: '100%',
            position: 'absolute',
         }}
      >
         <div 
            {...getRootProps({ className: 'dropzone' })}
            onDragLeave={onDragLeave}
         >
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
         </div>
      </section>
   )
}

export default DragAndDrop
