import './warningModal.css'
import { CSSTransition } from 'react-transition-group'

const WarningModal = ({ isVisible, title, text, yes, no, disableYes }) => {
   return (
      <CSSTransition
         in={isVisible}
         classNames="warning"
         timeout={500}
         mountOnEnter
         unmountOnExit
      >
         <div className="warning">
            <div className="warning_wrapper">
               <span className="warning_title">{title}</span>
               <p className="warning_text">{text}</p>
               <div className="warning_buttons">
                  <button className="warning_button yes" onClick={yes} disabled={disableYes}>
                     Да
                  </button>
                  <button className="warning_button no" onClick={no}>
                     Нет
                  </button>
               </div>
            </div>
         </div>
      </CSSTransition>
   )
}

export default WarningModal
