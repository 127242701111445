import React, { useState } from 'react'
import './Dropdown.css'
import { useClickOutside } from '../../hooks'

const TagDropdown = ({ btnText = '', items = [], onSelect }) => {
   const [isActive, setIsActive] = useState(false)

   const handleToggleDropdown = () => setIsActive(active => !active)

   const handleCloseDropDown = () => setIsActive(false)

   const ref = useClickOutside(handleCloseDropDown)

   return (
      <div className={`tag-dropdown ${isActive ? 'active' : ''}`} ref={ref}>
         <button 
            className='tag-dropdown__btn'
            onClick={handleToggleDropdown}   
         >
            {btnText}
            <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fill-rule="evenodd" clip-rule="evenodd" d="M4.54464 0.294156C4.7155 0.123302 4.99251 0.123302 5.16336 0.294156L8.66336 3.79416C8.83422 3.96501 8.83422 4.24202 8.66336 4.41288C8.49251 4.58373 8.2155 4.58373 8.04464 4.41288L4.854 1.22223L1.66336 4.41288C1.49251 4.58373 1.2155 4.58373 1.04464 4.41288C0.87379 4.24202 0.87379 3.96501 1.04464 3.79416L4.54464 0.294156Z" fill="#40B7D9"/>
            </svg>
         </button>
         <ul className='tag-dropdown__list'>
            {items.map(item => (
               <li
                  key={item.id}
                  className='tag-dropdown__list-item'
                  onClick={() => onSelect(item)}
               >
                  {(item.context_name && item.context_name !== 'name') 
                     ? `${item.context_name}` 
                     : 'Без названия'
                  }
               </li>
            ))}
         </ul>
      </div>
   )
}

export default TagDropdown