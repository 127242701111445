import { CSSTransition } from 'react-transition-group'
import React, { useEffect, useState } from 'react'
import './addMailing.css'
import Dropdown from '../settings/Dropdown'
import { messengerLimits } from '../../data/limits'
import axios from 'axios'
import WarningBlock from './WarningBlock'
import MailingFileNotification from './MailingFileNotification'
import Checkbox from '../Checkbox'
import { mailingTypesMap } from '../../utils/consts'

const AddMailing = ({
   isVisible,
   mailingType,
   toggleShowAddMailingModal,
   tag,
   allowedTag,
   status,
   allowedStatus,
   channelList,
   allowedChannel,
   setHistoryMailing,
   currentUser,
}) => {
   const [isShowChannelsDropdown, setIsShowChannelsDropdown] = useState(false)
   const [isShowTagsDropdown, setIsShowTagsDropdown] = useState(false)
   const [isShowStatusesDropdown, setIsShowStatusesDropdown] = useState(false)

   const [selectedChannels, setSelectedChannels] = useState([])
   const [allSelectedChannels, setAllSelectedChannels] = useState(false)
   const [areTheChannelsSelected, setTheChannelsSelected] = useState(false)

   const [selectedTags, setSelectedTags] = useState([])
   const [allSelectedTags, setAllSelectedTags] = useState(false)
   const [areTheTagsSelected, setTheTagsSelected] = useState(false)

   const [selectedStatuses, setSelectedStatuses] = useState([])
   const [allSelectedStatuses, setAllSelectedStatuses] = useState(false)
   const [areTheStatusesSelected, setTheStatusesSelected] = useState(false)

   const [textField, setTextField] = useState('')
   const [isTextFieldFilled, setIsTextFieldFilled] = useState(false)
   const [fileField, setFileField] = useState([])

   const [fileListField, setFileListField] = useState([])
   const [isFileListLoaded, setIsFileListLoaded] = useState(false)
   const [isFileListExtValid, setIsFileListExtValid] = useState(false)

   const [isShowDropdown, setIsShowDropdown] = useState(false)
   const toggleIsShowDropdown = () => {
      setIsShowDropdown(!isShowDropdown)
   }
   const [selectedChannelId, setSelectedChannelId] = useState(null)
   const [areTheChannelIdSelected, setTheChannelIdSelected] = useState(false)

   const [currentLimit, setCurrentLimit] = useState(null)
   const [isLoading, setLoading] = useState(false)

   const [serverError, setServerError] = useState('')
   const [isShowServerError, setIsShowServerError] = useState(false)

   const [contactList, setContactList] = useState([])
   const [contactListCount, setContactListCount] = useState(1)
   const [contactInputValue, setContactInputValue] = useState(null)
   const [areContactListExist, setClientsListExist] = useState(false)

   const clearInputs = () => {
      setIsShowChannelsDropdown(false)
      setIsShowTagsDropdown(false)
      setIsShowStatusesDropdown(false)
      setSelectedChannels([])
      setSelectedTags([])
      setSelectedStatuses([])
      setTextField('')
      setFileField([])
      setCurrentLimit(null)
      setIsTextFieldFilled(false)
      setTheChannelsSelected(false)
      setTheTagsSelected(false)
      setTheStatusesSelected(false)
      setFileListField([])
      setTheChannelIdSelected(false)
      setIsFileListExtValid(false)
      setContactList([])
      setClientsListExist(false)
      setSelectedChannelId(null)
      setContactInputValue(null)
   }

   const clearValidMessages = () => {
      setIsTextFieldFilled(false)
      setTheChannelsSelected(false)
      setTheTagsSelected(false)
      setTheStatusesSelected(false)
      setIsFileListLoaded(false)
      setTheChannelIdSelected(false)
      setIsFileListExtValid(false)
      setClientsListExist(false)
   }

   useEffect(() => {
      clearValidMessages();
      setSelectedChannelId(null)
   }, [mailingType]);

   const getAllowedConfig = () => {
      const output = {
         textLimit: Math.min(
            ...selectedChannels.map(
               (item) => messengerLimits[item.type].textLimit
            )
         ),
         documents: {
            formats: selectedChannels
               .map((item) => messengerLimits[item.type].documents.formats)
               .reduce((result, currentFormats) =>
                  result.filter((format) => currentFormats.includes(format))
               ),
            maxSize: Math.min(
               ...selectedChannels.map(
                  (item) => messengerLimits[item.type].documents.maxSize
               )
            ),
         },
         audio: {
            formats: selectedChannels
               .map((item) => messengerLimits[item.type].audio.formats)
               .reduce((result, currentFormats) =>
                  result.filter((format) => currentFormats.includes(format))
               ),
            maxSize: Math.min(
               ...selectedChannels.map(
                  (item) => messengerLimits[item.type].audio.maxSize
               )
            ),
            minDuration: Math.min(
               ...selectedChannels.map(
                  (item) => messengerLimits[item.type].audio.minDuration
               )
            ),
            maxDuration: Math.min(
               ...selectedChannels.map(
                  (item) => messengerLimits[item.type].audio.maxDuration
               )
            ),
         },
         video: {
            formats: selectedChannels
               .map((item) => messengerLimits[item.type].video.formats)
               .reduce((result, currentFormats) =>
                  result.filter((format) => currentFormats.includes(format))
               ),
            maxSize: Math.min(
               ...selectedChannels.map(
                  (item) => messengerLimits[item.type].video.maxSize
               )
            ),
            maxResolutions: {
               width: Math.min(
                  ...selectedChannels.map(
                     (item) =>
                        messengerLimits[item.type].video.maxResolutions.width
                  )
               ),
               height: Math.min(
                  ...selectedChannels.map(
                     (item) =>
                        messengerLimits[item.type].video.maxResolutions.height
                  )
               ),
            },
            maxDuration: Math.min(
               ...selectedChannels.map(
                  (item) => messengerLimits[item.type].video.maxDuration
               )
            ),
         },
         images: {
            formats: selectedChannels
               .map((item) => messengerLimits[item.type].images.formats)
               .reduce((result, currentFormats) =>
                  result.filter((format) => currentFormats.includes(format))
               ),
            maxSize: Math.min(
               ...selectedChannels.map(
                  (item) => messengerLimits[item.type].images.maxSize
               )
            ),
            minResolutions: {
               width: Math.min(
                  ...selectedChannels.map(
                     (item) =>
                        messengerLimits[item.type].images.minResolutions.width
                  )
               ),
               height: Math.min(
                  ...selectedChannels.map(
                     (item) =>
                        messengerLimits[item.type].images.minResolutions.height
                  )
               ),
            },
            maxResolutions: {
               width: Math.min(
                  ...selectedChannels.map(
                     (item) =>
                        messengerLimits[item.type].images.maxResolutions.width
                  )
               ),
               height: Math.min(
                  ...selectedChannels.map(
                     (item) =>
                        messengerLimits[item.type].images.maxResolutions.height
                  )
               ),
            },
         },
      }

      setCurrentLimit(output)
   }

   useEffect(() => {
      if (selectedChannels.length === 0) {
         setCurrentLimit(null)
      } else {
         getAllowedConfig()
      }
   }, [selectedChannels])

   const mailingHandler = () => {
      if (textField.length === 0) {
         setIsTextFieldFilled(true)
      } else {
         setIsTextFieldFilled(false)
      }
      if (selectedChannels.length === 0 && mailingType === mailingTypesMap.BY_FILTER) {
         setTheChannelsSelected(true)
      } else {
         setTheChannelsSelected(false)
      }
      if (selectedTags.length === 0 && mailingType === mailingTypesMap.BY_FILTER) {
         setTheTagsSelected(true)
      } else {
         setTheTagsSelected(false)
      }
      if (selectedStatuses.length === 0 && mailingType === mailingTypesMap.BY_FILTER) {
         setTheStatusesSelected(true)
      } else {
         setTheStatusesSelected(false)
      }
      if (fileListField.length === 0 && mailingType === mailingTypesMap.BY_CONTACTS_FILE) {
         setIsFileListLoaded(true)
      } else {
         setIsFileListLoaded(false)
      }
      if (selectedChannelId === null && (mailingType === mailingTypesMap.BY_CONTACTS_FILE || mailingType === mailingTypesMap.BY_NEW_CONTACTS)) {
         setTheChannelIdSelected(true)
      } else {
         setTheChannelIdSelected(false)
      }
      if (fileListField.length !== 0 && mailingType === mailingTypesMap.BY_CONTACTS_FILE) {
         if (checkFileExtension()) {
            setIsFileListExtValid(true)
         } else {
            setIsFileListExtValid(false)
         }
      }
      if (filterClientsList().length === 0 && mailingType === mailingTypesMap.BY_NEW_CONTACTS) {
         setClientsListExist(true)
      } else {
         setClientsListExist(false)
      }
      if (
          checkRequired()
      ) {
         setLoading(true)
         var bodyFormData = new FormData()
         bodyFormData.append('text', textField)

         if (fileField.length !== 0) {
            bodyFormData.append('files', fileField[0])
         }

         if (mailingType === mailingTypesMap.BY_FILTER) {
            bodyFormData.append('allChannels', allSelectedChannels)
            if (allSelectedChannels !== true) {
               console.log(selectedChannels.map((item) => item.id))
               bodyFormData.append(
                   'channelIds',
                   selectedChannels.map((item) => item.id)
               )
            }

            bodyFormData.append('allDialogSteps', allSelectedStatuses)
            if (allSelectedStatuses !== true) {
               bodyFormData.append(
                   'dialogStepIds',
                   selectedStatuses.map((item) => item.id)
               )
            }

            bodyFormData.append('allTags', allSelectedTags)
            if (allSelectedTags !== true) {
               bodyFormData.append(
                   'tagIds',
                   selectedTags.map((item) => item.id)
               )
            }
         }

         if (mailingType === mailingTypesMap.BY_CONTACTS_FILE) {
            if (selectedChannelId) {
               bodyFormData.append('channel', selectedChannelId)
            }

            if (fileListField.length !== 0) {
               bodyFormData.append('fileContactList', fileListField[0])
            }
         }

         if (mailingType === mailingTypesMap.BY_NEW_CONTACTS) {
            if (selectedChannelId) {
               bodyFormData.append('channel', selectedChannelId)
            }

            if (contactList.length !== 0) {
               bodyFormData.append('clientsList', filterClientsList())
            }
         }

         axios({
            method: 'post',
            url: '/api/v1/mass-send/send' + generateType(),
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
         })
            .then((response) => {
               if (response.data.error) {
                  setServerError(response.data.data.error)
                  setLoading(false)
                  return setIsShowServerError(true)
               } else {
                  clearInputs()
                  toggleShowAddMailingModal(false)
                  setHistoryMailing((prevState) => {
                     return [response.data.data, ...prevState]
                  })
               }
               setLoading(false)
            })
            .catch((err) => {
               window.alert(err)
               window.location.reload(false)
            })
      }
   }

   const getSelectedTitle = () => {
      if (!selectedChannelId) {
         return 'Только выбранные'
      }
      const findSelected = channelList.find(item => item.id === selectedChannelId)
      return findSelected.type + ' - ' + findSelected.username
   }

   const handleAddReceiverClick = () => {
      setContactListCount((prev) => prev + 1)
      setContactInputValue('')
   }

   const handleDelete = (client) => {
      let tempList = contactList
      if (client.name > -1) tempList.splice(client.name, 1)
      setContactList(tempList)
   }

   const handleAddContact = (client) => {
      setContactInputValue(client.value)
      let tempList = contactList
      tempList[client.name] = client.value
      setContactList(tempList)
   }

   const checkFileExtension = () => {
      let textValidator = '.txt'
      return fileListField[0].name.indexOf(textValidator) === -1
   }

   const filterClientsList = () => {
      return contactList.filter((client) => client !== null)
   }

   const checkRequired = () => {
      switch (mailingType) {
         case mailingTypesMap.BY_FILTER:
            return selectedChannels.length !== 0 &&
            selectedTags.length !== 0 &&
            selectedStatuses.length !== 0 &&
            textField.length !== 0
         case mailingTypesMap.BY_CONTACTS_FILE:
            return selectedChannelId !== null && fileListField.length !== 0 && textField.length !== 0
         case mailingTypesMap.BY_NEW_CONTACTS:
            return selectedChannelId !== null && filterClientsList().length !== 0 && textField.length !== 0
         default:
            return
      }
   }

   const generateType = () => {
      switch (mailingType) {
         case mailingTypesMap.BY_FILTER:
            return '?type=filter'
         case mailingTypesMap.BY_CONTACTS_FILE:
            return '?type=file'
         case mailingTypesMap.BY_NEW_CONTACTS:
            return '?type=contact_list'
         default:
            return
      }
   }

   const filterAllowedChannels = () => {
      if (allowedChannel === null) {
         return channelList
      }
      let filteredChannelList = []
      allowedChannel.forEach((channelId) => {
         filteredChannelList.push(channelList.find(
           (channel) => channelId === channel.id
       ))
      })

      return filteredChannelList.filter(item => item !== undefined)
   }

   return (
      <CSSTransition
         in={isVisible}
         classNames="warning"
         timeout={500}
         mountOnEnter
         unmountOnExit
      >
         <div className="warning">
            <div className="basic_wrapper">
               <MailingFileNotification
                  title={'Уведомление'}
                  file={fileField.length === 0 ? '' : fileField[0].name}
                  text={serverError}
                  button={'Изменить рассылку'}
                  yes={() => setIsShowServerError(false)}
                  isVisible={isShowServerError}
               />
               <div className="basic_header">
                  <span className="basic_title">Новая рассылка</span>
                  <div className="basic-close">
                     <button
                        className="basic-close-button"
                        onClick={() => {
                           clearInputs()
                           toggleShowAddMailingModal()
                        }}
                     >
                        <img src="/assets/controls/close.svg" alt="close" />
                     </button>
                  </div>
               </div>

               {
                  mailingType === mailingTypesMap.BY_FILTER && (
                      <>
                         <div className="mailing-control-block">
                            <label
                                className={
                                   areTheChannelsSelected
                                       ? 'mailing-label-red'
                                       : 'mailing-label'
                                }
                                htmlFor=""
                            >
                               Выбрать канал
                            </label>
                            <div
                                onClick={() => {
                                   setIsShowStatusesDropdown(false)
                                   setIsShowTagsDropdown(false)
                                }}
                            >
                               <Dropdown
                                   items={
                                      allowedChannel === null
                                          ? channelList
                                          : allowedChannel.map((channelId) => {
                                             return channelList.find(
                                                 (channel) => channelId === channel.id
                                             )
                                          }).filter(item => item !== undefined)
                                   }
                                   selectedItems={selectedChannels}
                                   setSelectedItems={setSelectedChannels}
                                   isCheckedAllItems={allSelectedChannels}
                                   setIsCheckedAllItems={setAllSelectedChannels}
                                   allowedItems={allowedChannel}
                                   isShowItemDropdown={isShowChannelsDropdown}
                                   setIsShowItemDropdown={setIsShowChannelsDropdown}
                               />
                            </div>
                         </div>

                         <div className="mailing-control-block">
                            <label
                                className={
                                   areTheStatusesSelected
                                       ? 'mailing-label-red'
                                       : 'mailing-label'
                                }
                                htmlFor=""
                            >
                               Выбрать статусы
                            </label>
                            <div
                                onClick={() => {
                                   setIsShowChannelsDropdown(false)
                                   setIsShowTagsDropdown(false)
                                }}
                            >
                               <Dropdown
                                   items={
                                      allowedStatus === null
                                          ? status
                                          : allowedStatus.map((statusId) => {
                                             return status.find(
                                                 (st) => statusId === st.id
                                             )
                                          })
                                   }
                                   selectedItems={selectedStatuses}
                                   setSelectedItems={setSelectedStatuses}
                                   isCheckedAllItems={allSelectedStatuses}
                                   setIsCheckedAllItems={setAllSelectedStatuses}
                                   allowedItems={allowedStatus}
                                   isShowItemDropdown={isShowStatusesDropdown}
                                   setIsShowItemDropdown={setIsShowStatusesDropdown}
                               />
                            </div>
                         </div>
                         <div className="mailing-control-block">
                            <label
                                className={
                                   areTheTagsSelected
                                       ? 'mailing-label-red'
                                       : 'mailing-label'
                                }
                                htmlFor=""
                            >
                               Выбрать теги
                            </label>
                            <div
                                onClick={() => {
                                   setIsShowStatusesDropdown(false)
                                   setIsShowChannelsDropdown(false)
                                }}
                            >
                               <Dropdown
                                   items={
                                      allowedTag === null
                                          ? tag
                                          : allowedTag.map((tagId) => {
                                             return tag.find((tag) => tagId === tag.id)
                                          })
                                   }
                                   selectedItems={selectedTags}
                                   setSelectedItems={setSelectedTags}
                                   isCheckedAllItems={allSelectedTags}
                                   setIsCheckedAllItems={setAllSelectedTags}
                                   allowedItems={allowedTag}
                                   isShowItemDropdown={isShowTagsDropdown}
                                   setIsShowItemDropdown={setIsShowTagsDropdown}
                               />
                            </div>
                         </div>
                      </>
                  )
               }

               {
                  mailingType === mailingTypesMap.BY_CONTACTS_FILE && (
                      <>
                         <div className="mailing-control-block">
                            <div className="dropdown">
                               <div className="dropdown-wrapper" style={{ width: '100%' }}>
                                  <label
                                      className={
                                         areTheChannelIdSelected
                                             ? 'mailing-label-red'
                                             : 'mailing-label'
                                      }
                                      htmlFor=""
                                  >
                                     Выбрать канал
                                  </label>
                                  <div
                                      className="dropdown-current-item"
                                      onClick={toggleIsShowDropdown}
                                  >
                                     <span className="dropdown-current-item_name">
                                        {getSelectedTitle()}
                                     </span>
                                     <svg
                                         width="24"
                                         height="24"
                                         viewBox="0 0 24 24"
                                         fill="none"
                                         xmlns="http://www.w3.org/2000/svg"
                                     >
                                        {isShowDropdown ? (
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M11.2197 8.21967C11.5126 7.92678 11.9874 7.92678 12.2803 8.21967L18.2803 14.2197C18.5732 14.5126 18.5732 14.9874 18.2803 15.2803C17.9874 15.5732 17.5126 15.5732 17.2197 15.2803L11.75 9.81066L6.28033 15.2803C5.98744 15.5732 5.51256 15.5732 5.21967 15.2803C4.92678 14.9874 4.92678 14.5126 5.21967 14.2197L11.2197 8.21967Z"
                                                fill="#252525"
                                            />
                                        ) : (
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M5.21967 8.21967C5.51256 7.92678 5.98744 7.92678 6.28033 8.21967L11.75 13.6893L17.2197 8.21967C17.5126 7.92678 17.9874 7.92678 18.2803 8.21967C18.5732 8.51256 18.5732 8.98744 18.2803 9.28033L12.2803 15.2803C11.9874 15.5732 11.5126 15.5732 11.2197 15.2803L5.21967 9.28033C4.92678 8.98744 4.92678 8.51256 5.21967 8.21967Z"
                                                fill="#252525"
                                            />
                                        )}
                                     </svg>
                                  </div>
                                  {isShowDropdown && (
                                      <div
                                          className="dropdown-list"
                                          style={{ zIndex: '9999', width: '328px' }}
                                      >
                                         <div
                                             className="dropdown-list_wrapper"
                                             style={{ padding: '20px 24px' }}
                                         >
                                            {
                                               <ul className="dropdown-list_items">
                                                  {filterAllowedChannels()?.map((item) => {
                                                     const isChecked = selectedChannelId === item.id
                                                     return (
                                                         <li
                                                             className="dropdown-list_item"
                                                             style={{
                                                                margin: '0',
                                                                marginBottom: '16px',
                                                                cursor: 'pointer',
                                                             }}
                                                             key={item.id}
                                                             onClick={() => {
                                                                setSelectedChannelId(item.id)
                                                                setIsShowDropdown(false)
                                                             }}
                                                         >
                                                            <Checkbox
                                                               checked={isChecked}
                                                            />
                                                            <div className="dropdown-list_item-block">
                                                               {item.type + ' - ' + item.username}
                                                            </div>
                                                         </li>
                                                     )
                                                  })}
                                               </ul>
                                            }
                                         </div>
                                      </div>
                                  )}
                               </div>
                            </div>
                         </div>

                         <div className="mailing-control-block">
                            <label
                                className={
                                   isFileListLoaded || isFileListExtValid
                                       ? 'mailing-label-red question'
                                       : 'mailing-label question'
                                }
                                htmlFor=""
                            >
                               Добавить контакты с txt файла
                               <div className="mailing-contact-file-info">
                                  <img
                                      src="/assets/chat/question-circular.svg"
                                      alt=""
                                      style={{ width: '15px', marginLeft: '3px'}}
                                  />
                                  <div className="mailing-contact-file-info-block cssAnimate">
                                     Максимальный размер файла 500КБ.
                                     <br/>
                                     <br/>
                                     В качестве контактов допускаются:
                                     <br/><b>WhatsApp</b> - номер телефона, пример: <i>89997776655</i>
                                     <br/><b>Instagram</b> - имя пользователя, пример: <i>user_12345</i>.
                                     <br/><b>Telegram</b> - имя пользователя, пример: <i>user_12345</i>
                                     <br/><b>ВКонтакте</b> - имя пользователя, пример: <i>user_12.34-5</i>
                                     <br/>
                                     <br/>
                                     Каждый контакт записывается с новой строчки без дополнительных знаков разделения.
                                  </div>
                               </div>
                            </label>
                            <div
                                className="add-contact-form-control"
                                style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}
                            >

                               <label
                                   className={
                                      fileListField.length === 0
                                          ? 'settings-template_file-button'
                                          : 'settings-template_file-button-limit'
                                   }
                                   htmlFor="add-list"
                               >
                                  <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill={fileListField.length === 0 ? '#40b7d9' : '#B8C5C9'}
                                  >
                                     <path
                                         fill-rule="evenodd"
                                         clip-rule="evenodd"
                                         d="M15.3587 4.95927C16.8232 3.49478 19.1976 3.4948 20.6621 4.95926L20.1328 5.4885L20.6621 4.95927C22.1265 6.42372 22.1265 8.79808 20.6621 10.2626L20.6621 10.2626L11.8232 19.1014C9.77296 21.1516 6.44884 21.1516 4.39859 19.1014L4.92892 18.571L4.39859 19.1014C2.34834 17.0511 2.34834 13.727 4.39859 11.6768L11.8232 4.25215C12.1161 3.95925 12.591 3.95925 12.8839 4.25215C13.1767 4.54504 13.1767 5.01991 12.8839 5.31281L5.45925 12.7374L4.92892 12.2071L5.45925 12.7374C3.99478 14.2019 3.99478 16.5763 5.45925 18.0407L4.93823 18.5617L5.45925 18.0407C6.92371 19.5052 9.29809 19.5052 10.7625 18.0407L11.2575 18.5357L10.7625 18.0407L19.6014 9.20193C19.6014 9.20192 19.6014 9.20192 19.6014 9.20192C20.4801 8.3232 20.48 6.89857 19.6014 6.01993C18.7227 5.14123 17.2981 5.14124 16.4194 6.01992L16.4194 6.01993L7.58058 14.8587C7.28769 15.1516 7.28769 15.6265 7.58058 15.9194C7.87349 16.2123 8.34835 16.2123 8.64124 15.9194L16.0658 8.49477C16.3587 8.20187 16.8336 8.20187 17.1265 8.49477C17.4194 8.78766 17.4194 9.26253 17.1265 9.55543L9.70191 16.9801C8.82321 17.8588 7.39859 17.8588 6.5199 16.9801L6.51987 16.9801C5.64126 16.1014 5.64126 14.6768 6.51987 13.7981L6.5199 13.7981L15.3587 4.95928C15.3587 4.95927 15.3587 4.95927 15.3587 4.95927Z"
                                     />
                                  </svg>
                                  {fileListField.length === 0
                                      ? 'Прикрепить файл'
                                      : fileListField[0].name}
                               </label>
                               <input
                                   id="add-list"
                                   type="file"
                                   src={fileListField}
                                   onChange={(e) => {
                                      setFileListField([...e.target.files])
                                   }}
                                   className="settings-template_file-input"
                               />
                               {fileListField.length !== 0 && (
                                   <img
                                       width="16"
                                       height="16"
                                       style={{ margin: '0', cursor: 'pointer' }}
                                       src="/assets/controls/close.svg"
                                       onClick={() => {
                                          setFileListField([])
                                       }}
                                   ></img>
                               )}
                            </div>
                         </div>
                      </>
                  )
               }

               {
                  mailingType === mailingTypesMap.BY_NEW_CONTACTS && (
                      <>
                         <div className="mailing-control-block">
                            <div className="dropdown">
                               <div className="dropdown-wrapper" style={{ width: '100%' }}>
                                  <label
                                      className={
                                         areTheChannelIdSelected
                                             ? 'mailing-label-red'
                                             : 'mailing-label'
                                      }
                                      htmlFor=""
                                  >
                                     Выбрать канал
                                  </label>
                                  <div
                                      className="dropdown-current-item"
                                      onClick={toggleIsShowDropdown}
                                  >
                                     <span className="dropdown-current-item_name">
                                        {getSelectedTitle()}
                                     </span>
                                     <svg
                                         width="24"
                                         height="24"
                                         viewBox="0 0 24 24"
                                         fill="none"
                                         xmlns="http://www.w3.org/2000/svg"
                                     >
                                        {isShowDropdown ? (
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M11.2197 8.21967C11.5126 7.92678 11.9874 7.92678 12.2803 8.21967L18.2803 14.2197C18.5732 14.5126 18.5732 14.9874 18.2803 15.2803C17.9874 15.5732 17.5126 15.5732 17.2197 15.2803L11.75 9.81066L6.28033 15.2803C5.98744 15.5732 5.51256 15.5732 5.21967 15.2803C4.92678 14.9874 4.92678 14.5126 5.21967 14.2197L11.2197 8.21967Z"
                                                fill="#252525"
                                            />
                                        ) : (
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M5.21967 8.21967C5.51256 7.92678 5.98744 7.92678 6.28033 8.21967L11.75 13.6893L17.2197 8.21967C17.5126 7.92678 17.9874 7.92678 18.2803 8.21967C18.5732 8.51256 18.5732 8.98744 18.2803 9.28033L12.2803 15.2803C11.9874 15.5732 11.5126 15.5732 11.2197 15.2803L5.21967 9.28033C4.92678 8.98744 4.92678 8.51256 5.21967 8.21967Z"
                                                fill="#252525"
                                            />
                                        )}
                                     </svg>
                                  </div>
                                  {isShowDropdown && (
                                      <div
                                          className="dropdown-list"
                                          style={{ zIndex: '9999', width: '328px' }}
                                      >
                                         <div
                                             className="dropdown-list_wrapper"
                                             style={{ padding: '20px 24px' }}
                                         >
                                            {
                                               <ul className="dropdown-list_items">
                                                  {filterAllowedChannels()?.map((item) => {
                                                     const isChecked = selectedChannelId === item.id
                                                     return (
                                                         <li
                                                             className="dropdown-list_item"
                                                             style={{
                                                                margin: '0',
                                                                marginBottom: '16px',
                                                                cursor: 'pointer',
                                                             }}
                                                             key={item.id}
                                                             onClick={() => {
                                                                setSelectedChannelId(item.id)
                                                                setIsShowDropdown(false)
                                                             }}
                                                         >
                                                            <Checkbox
                                                               checked={isChecked}
                                                            />
                                                            <div className="dropdown-list_item-block">
                                                               {item.type + ' - ' + item.username}
                                                            </div>
                                                         </li>
                                                     )
                                                  })}
                                               </ul>
                                            }
                                         </div>
                                      </div>
                                  )}
                               </div>
                            </div>
                         </div>

                         <div className="mailing-contact-list-block">
                            <label
                                className={
                                   areContactListExist
                                       ? 'mailing-label-red'
                                       : 'mailing-label'
                                }
                                htmlFor=""
                            >
                               Список контактов
                            </label>
                            <div className="mailing-list_input-wrap">
                               <input
                                   type="text"
                                   className="mailing-list_input"
                                   onChange={(e) => handleAddContact(e.target)}
                                   name={contactListCount}
                                   value={contactInputValue}
                                   placeholder="Добавить контакт"
                               />
                               <button
                                   className="mailing-list-add-contact-button"
                                   onClick={handleAddReceiverClick}
                               >
                                  Добавить
                               </button>
                               <div className="mass-send-instructions">
                                 <span>
                                 Введите контакт в поле, нажмите добавить,
                                 введите следующий контакт.
                                 </span>
                               </div>

                            </div>
                            <div
                                className="mailing-contact-list-display"
                            >
                               {contactList.map((client, index) => (
                                   <span
                                       key={index}
                                       className="mailing-single-contact-field"
                                       title={client}
                                   >
                                 {client}
                                      <img
                                          src="/assets/controls/delete.svg"
                                          alt=""
                                          className="mailing-list_delete-button"
                                          name={index}
                                          onClick={(e) => {
                                             handleDelete(e.target)
                                             setContactListCount(
                                                 (prev) => prev - 1 > 0 && prev - 1
                                             )
                                          }}
                                      />
                                </span>
                               ))}
                            </div>
                         </div>

                      </>
                  )
               }

               <div className="mailing-control-block">
                  <label
                     className={
                        isTextFieldFilled
                           ? 'mailing-label-red'
                           : 'mailing-label'
                     }
                     htmlFor=""
                  >
                     Текст рассылки
                  </label>
                  <textarea
                     value={textField}
                     onChange={(e) => {
                        setTextField(e.target.value)
                     }}
                     placeholder="Текст рассылки"
                     minLength="1"
                     maxLength={
                        currentLimit === null ? '' : currentLimit.textLimit
                     }
                     className={
                        isTextFieldFilled
                           ? 'mailing-textarea-red'
                           : 'mailing-textarea'
                     }
                  ></textarea>
               </div>
               <div
                  className="add-contact-form-control"
                  style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}
               >
                  <label
                     className={
                        fileField.length === 0
                           ? 'settings-template_file-button'
                           : 'settings-template_file-button-limit'
                     }
                     htmlFor="add-file"
                  >
                     <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        fill={fileField.length === 0 ? '#40b7d9' : '#B8C5C9'}
                     >
                        <path
                           fill-rule="evenodd"
                           clip-rule="evenodd"
                           d="M15.3587 4.95927C16.8232 3.49478 19.1976 3.4948 20.6621 4.95926L20.1328 5.4885L20.6621 4.95927C22.1265 6.42372 22.1265 8.79808 20.6621 10.2626L20.6621 10.2626L11.8232 19.1014C9.77296 21.1516 6.44884 21.1516 4.39859 19.1014L4.92892 18.571L4.39859 19.1014C2.34834 17.0511 2.34834 13.727 4.39859 11.6768L11.8232 4.25215C12.1161 3.95925 12.591 3.95925 12.8839 4.25215C13.1767 4.54504 13.1767 5.01991 12.8839 5.31281L5.45925 12.7374L4.92892 12.2071L5.45925 12.7374C3.99478 14.2019 3.99478 16.5763 5.45925 18.0407L4.93823 18.5617L5.45925 18.0407C6.92371 19.5052 9.29809 19.5052 10.7625 18.0407L11.2575 18.5357L10.7625 18.0407L19.6014 9.20193C19.6014 9.20192 19.6014 9.20192 19.6014 9.20192C20.4801 8.3232 20.48 6.89857 19.6014 6.01993C18.7227 5.14123 17.2981 5.14124 16.4194 6.01992L16.4194 6.01993L7.58058 14.8587C7.28769 15.1516 7.28769 15.6265 7.58058 15.9194C7.87349 16.2123 8.34835 16.2123 8.64124 15.9194L16.0658 8.49477C16.3587 8.20187 16.8336 8.20187 17.1265 8.49477C17.4194 8.78766 17.4194 9.26253 17.1265 9.55543L9.70191 16.9801C8.82321 17.8588 7.39859 17.8588 6.5199 16.9801L6.51987 16.9801C5.64126 16.1014 5.64126 14.6768 6.51987 13.7981L6.5199 13.7981L15.3587 4.95928C15.3587 4.95927 15.3587 4.95927 15.3587 4.95927Z"
                        />
                     </svg>
                     {fileField.length === 0
                        ? 'Прикрепить файл'
                        : fileField[0].name}
                  </label>
                  <input
                     id="add-file"
                     type="file"
                     src={fileField}
                     onChange={(e) => {
                        console.log(e.target.files)
                        setFileField([...e.target.files])
                     }}
                     className="settings-template_file-input"
                  />
                  {fileField.length !== 0 && (
                     <img
                        width="16"
                        height="16"
                        style={{ margin: '0', cursor: 'pointer' }}
                        src="/assets/controls/close.svg"
                        onClick={() => {
                           setFileField([])
                        }}
                     ></img>
                  )}
               </div>
               {areTheChannelsSelected && (
                  <WarningBlock title={'Не выбран ни один канал.'} />
               )}
               {areTheTagsSelected && (
                  <WarningBlock title={'Не выбран ни один тег.'} />
               )}
               {areTheStatusesSelected && (
                  <WarningBlock title={'Не выбран ни один статус.'} />
               )}
               {areTheChannelIdSelected && (
                   <WarningBlock title={'Не выбран канал.'} />
               )}
               {isFileListLoaded && (
                   <WarningBlock title={'Не выбран файл списка рассылки.'} />
               )}
               {isFileListExtValid && (
                   <WarningBlock title={'Разрешенное расширение файла с контактами - .txt.'} />
               )}
               {areContactListExist && (
                   <WarningBlock title={'Не заполнен список контактов.'} />
               )}
               {isTextFieldFilled && (
                  <WarningBlock title={'Введите текст рассылки.'} />
               )}
               <div className="add-contact-form-submit">
                  {isLoading ? (
                     <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                     </div>
                  ) : (
                     <button
                        onClick={mailingHandler}
                        className="add-contact-form-submit-button"
                     >
                        Отправить рассылку
                     </button>
                  )}
               </div>
            </div>
         </div>
      </CSSTransition>
   )
}

export default AddMailing
