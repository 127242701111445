import React, { useRef, useEffect } from 'react'
import useState from 'react-usestateref'

import axios from 'axios'
import './addContact.css'
import './Previewer.css'

const Previewer = ({
   close,
   imagePreviewModal,
   imagePreviewLink,
   imagePreviewType,
   imagePreviewId,
   selectedConversation,
   setImagePreviewLink,
   setImagePreviewModal,
   setImagePreviewType,
   setImagePreviewId,
   imagePreviewLinkRef,
   imagePreviewIdRef,
   imagePreviewTypeRef,
   imagePreviewThumb,
   setImagePreviewThumb,
}) => {
   const addTagModalStyle = {
      display: imagePreviewModal ? 'block' : 'none',
   }
   const [currentMediaIndex, setCurrentMediaIndex, currentMediaIndexRef] =
      useState()
   const [currentMediaList, setCurrentMediaList, currentMediaListRef] =
      useState(null)
   const [previewLoader, setPreviewLoader] = useState(true)
   const [mediaLink, setMediaLink] = useState(imagePreviewLink)

   const vidRef = useRef(null)

   const handleClosePreviewer = () => {
      close()
   }

   useEffect(() => {
      if (vidRef.current !== null) {
         if (imagePreviewModal === true) {
            vidRef.current.play()
         }
         if (imagePreviewModal === false && vidRef.current !== null) {
            vidRef.current.pause()
         }
      }
   }, [imagePreviewModal])

   useEffect(() => {
      imagePreviewType &&
         imagePreviewLink &&
         imagePreviewId &&
         axios
            .get(
               `/api/v1/message/get-media?dialog=${selectedConversation}&message=${imagePreviewId}&new=false`
            )
            .then((response) => {
               if (response.data.error) {
                  window.alert(response.data.data.error)
               } else {
                  setCurrentMediaList(response.data.data.messages.reverse())
               }
            })
            .catch((err) => console.log(err))
            .then(() => {
               axios
                  .get(
                     `/api/v1/message/get-media?dialog=${selectedConversation}&message=${imagePreviewId}&new=true`
                  )
                  .then((response) => {
                     if (response.data.error) {
                        window.alert(response.data.data.error)
                     } else {
                        setCurrentMediaIndex(currentMediaListRef.current.length)
                        setCurrentMediaList([
                           ...currentMediaListRef.current,
                           {
                              message_id: imagePreviewId,
                              dialog_id: selectedConversation,
                              type: imagePreviewType,
                              link: imagePreviewLink,
                              thumb: imagePreviewThumb,
                           },
                           ...response.data.data.messages,
                        ])
                     }
                  })
                  .catch((err) => console.log(err))
            })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const getNextTwenty = () => {
      imagePreviewIdRef.current &&
         axios
            .get(
               `/api/v1/message/get-media?dialog=${selectedConversation}&message=${imagePreviewIdRef.current}&new=false`
            )
            .then((response) => {
               if (response.data.error) {
                  window.alert(response.data.data.error)
               } else {
                  setCurrentMediaList(response.data.data.messages.reverse())
               }
            })
            .catch((err) => console.log(err))
            .then(() => {
               axios
                  .get(
                     `/api/v1/message/get-media?dialog=${selectedConversation}&message=${imagePreviewIdRef.current}&new=true`
                  )
                  .then((response) => {
                     if (response.data.error) {
                        window.alert(response.data.data.error)
                     } else {
                        setCurrentMediaIndex(currentMediaListRef.current.length)
                        setCurrentMediaList([
                           ...currentMediaListRef.current,
                           {
                              message_id: imagePreviewIdRef.current,
                              dialog_id: selectedConversation,
                              type: imagePreviewTypeRef.current,
                              link: imagePreviewLinkRef.current,
                              thumb: imagePreviewThumb,
                           },
                           ...response.data.data.messages,
                        ])
                     }
                  })
                  .catch((err) => console.log(err))
            })
   }

   const handlePreviousMediaClick = () => {
      if (
         typeof currentMediaListRef.current[
            currentMediaIndexRef.current - 1
         ] === 'undefined'
      ) {
         setImagePreviewId(currentMediaListRef.current[0].message_id)
         getNextTwenty()
      } else {
         setImagePreviewId(
            currentMediaListRef.current[currentMediaIndexRef.current - 1]
               .message_id
         )
         setImagePreviewType(
            currentMediaListRef.current[currentMediaIndexRef.current - 1].type
         )
         setImagePreviewLink(
            currentMediaListRef.current[currentMediaIndexRef.current - 1].link
         )
         setMediaLink(
            currentMediaListRef.current[currentMediaIndexRef.current - 1].link
         )
         setCurrentMediaIndex(currentMediaIndexRef.current - 1)
      }
   }

   const handleNextMediaClick = () => {
      if (
         typeof currentMediaListRef.current[
            currentMediaIndexRef.current + 1
         ] === 'undefined'
      ) {
         setImagePreviewId(
            currentMediaListRef.current[currentMediaListRef.current.length - 1]
               .message_id
         )
         getNextTwenty()
      } else {
         setImagePreviewId(
            currentMediaListRef.current[currentMediaIndexRef.current + 1]
               .message_id
         )
         setImagePreviewLink(
            currentMediaListRef.current[currentMediaIndexRef.current + 1].link
         )
         setMediaLink(
            currentMediaListRef.current[currentMediaIndexRef.current + 1].link
         )
         setImagePreviewType(
            currentMediaListRef.current[currentMediaIndexRef.current + 1].type
         )
         setCurrentMediaIndex(currentMediaIndexRef.current + 1)
      }
   }

   useEffect(() => {
      const handleKeyboardClick = (e) => {
         if (e.keyCode === 37) {
            // left arrow
            handlePreviousMediaClick()
         } else if (e.keyCode === 39) {
            // right arrow
            handleNextMediaClick()
         } else if (e.key === 'Escape') {
            handleClosePreviewer()
         }
      }

      document.addEventListener('keydown', handleKeyboardClick)
      return function cleanup() {
         document.removeEventListener('keydown', handleKeyboardClick)
      }
   }, [])

   return (
      <div className="add-contact" style={addTagModalStyle} tabIndex={0}>
         <div
            className="add-contact-wrapper previewer__body"
            onClick={(e) => e.stopPropagation()}
            style={{
               position: 'relative',
               width: '80%',
               maxHeight: '95%',
               height: '80%',
            }}
         >
            <div className="previewer-close-btn">
               <button
                  className="add-contact-close-button"
                  onClick={handleClosePreviewer}
               >
                  <img src="/assets/controls/close.svg" alt="" />
               </button>
            </div>
            <img
               src="/assets/controls/chevron-left.svg"
               alt=""
               style={{
                  position: 'absolute',
                  top: '50%',
                  left: '10px',
                  width: '30px',
                  cursor: 'pointer',
               }}
               onClick={handlePreviousMediaClick}
            />
            <img
               src="/assets/controls/chevron-right.svg"
               alt=""
               style={{
                  position: 'absolute',
                  top: '50%',
                  right: '10px',
                  width: '30px',
                  cursor: 'pointer',
               }}
               onClick={handleNextMediaClick}
            />
            {imagePreviewType === 'image' || imagePreviewType === 'media' ? (
               <div className='previewer__image'>
                  <img
                     src={mediaLink}
                     alt=""
                     style={{
                        display: previewLoader ? 'none' : 'block',
                     }}
                     onLoad={() => setPreviewLoader(false)}
                  />
               </div>
            ) : (
               imagePreviewType === 'video' && (
                  <video
                     ref={vidRef}
                     controls
                     preload="auto"
                     autoPlay
                     style={{
                        display: previewLoader ? 'none' : 'block',
                     }}
                     src={mediaLink}
                     type="video/mp4"
                     onLoadStart={() => setPreviewLoader(false)}
                  />
               )
            )}
            {previewLoader && (
               <div className="auth-loader">
                  <div className="lds-ellipsis">
                     <div></div>
                     <div></div>
                     <div></div>
                     <div></div>
                  </div>
               </div>
            )}
         </div>
      </div>
   )
}

export default Previewer
