import React, { useState } from 'react'
import './addContact.css'
import { allColors } from '../../colors/status'
import { CSSTransition } from 'react-transition-group'
import WarningBlock from './WarningBlock'

const StatusModal = ({ isVisible, title, text, element, accept, cancel }) => {
   const [statusField, setStatusField] = useState('')
   const [statusFieldIsNotFilled, setStatusFieldIsNotFilled] = useState(false)
   const [statusSymbolsIsNotEnough, setStatusSymbolsIsNotEnough] =
      useState(false)

   const [statusColor, setStatusColor] = useState('')
   const [isAnotherColors, setIsAnotherColors] = useState(false)
   const [isColorNotChosen, setIsColorNotChosen] = useState(false)

   const toggleAnotherColors = () => {
      setIsAnotherColors(!isAnotherColors)
   }

   const [colors, setColors] = useState(allColors)

   const checkColor = (currentColor) => {
      setColors((prevState) => {
         return prevState.map((color) => {
            if (color.id !== currentColor.id)
               return { ...color, isChecked: false }
            return { ...color, isChecked: true }
         })
      })
      setStatusColor(currentColor.color)
   }

   const uncheckColor = () => {
      setColors((prevState) => {
         return prevState.map((color) => {
            return { ...color, isChecked: false }
         })
      })
   }

   const statusHandler = () => {
      if (statusField.length === 0) {
         setStatusFieldIsNotFilled(true)
      } else {
         setStatusFieldIsNotFilled(false)
      }

      if (statusColor.length === 0) {
         setIsColorNotChosen(true)
      } else {
         setIsColorNotChosen(false)
      }

      if (statusField.length <= 3 && statusField.length > 0) {
         setStatusSymbolsIsNotEnough(true)
      } else {
         setStatusSymbolsIsNotEnough(false)
      }

      if (
         statusFieldIsNotFilled === false &&
         statusField.length >= 4 &&
         statusColor.length > 0
      ) {
         accept({ id: element.id || '', name: statusField, color: statusColor })
         setStatusField('')
         setStatusColor('')
         setIsAnotherColors(false)
         cancel()
         setStatusFieldIsNotFilled(false)
         setIsColorNotChosen(false)
         setStatusSymbolsIsNotEnough(false)
         uncheckColor()
      }
   }

   return (
      <CSSTransition
         in={isVisible}
         classNames="add-contact"
         timeout={500}
         mountOnEnter
         unmountOnExit
      >
         <div className="add-contact">
            <div
               className="add-contact-wrapper"
               onClick={(e) => e.stopPropagation()}
            >
               <div className="add-contact-header">
                  <div className="add-contact-header-text">{title}</div>
                  <div className="add-contact-close">
                     <button
                        className="add-contact-close-button"
                        onClick={() => {
                           cancel()
                           uncheckColor()
                           setStatusField('')
                           setStatusColor('')
                           setIsAnotherColors(false)
                           setStatusFieldIsNotFilled(false)
                           setIsColorNotChosen(false)
                           setStatusSymbolsIsNotEnough(false)
                        }}
                     >
                        <img src="/assets/controls/close.svg" alt="" />
                     </button>
                  </div>
               </div>
               <div className="add-contact-form-control">
                  <label
                     htmlFor=""
                     className={
                        statusFieldIsNotFilled || statusSymbolsIsNotEnough
                           ? 'add-contact-form-control-label-red'
                           : 'add-contact-form-control-label'
                     }
                  >
                     Название статуса*
                  </label>
                  <input
                     type="text"
                     className={
                        statusFieldIsNotFilled || statusSymbolsIsNotEnough
                           ? 'add-contact-form-control-input-red'
                           : 'add-contact-form-control-input'
                     }
                     placeholder="Текст"
                     value={statusField}
                     maxLength="19"
                     onChange={(e) => setStatusField(e.target.value)}
                  />
               </div>
               <div className="add-contact-color-control">
                  <label
                     htmlFor=""
                     className={
                        isColorNotChosen
                           ? 'add-contact-form-control-label-red'
                           : 'add-contact-form-control-label'
                     }
                  >
                     Добавить цвет статуса*
                  </label>
                  <div className="add-contact-form-color-block">
                     {colors.map((color, index) => {
                        if (index <= 4)
                           return (
                              <button
                                 key={color.id}
                                 onClick={() => checkColor(color)}
                                 style={{ backgroundColor: color.color }}
                                 className="add-contact-form-color-item"
                              >
                                 {color.isChecked ? (
                                    <svg
                                       width="17"
                                       height="12"
                                       viewBox="0 0 17 12"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M15.8196 0.930612C16.0881 1.1991 16.0881 1.6344 15.8196 1.90288L6.65297 11.0695C6.38448 11.338 5.94918 11.338 5.68069 11.0695L1.09736 6.48622C0.828875 6.21773 0.828875 5.78243 1.09736 5.51395C1.36585 5.24546 1.80115 5.24546 2.06963 5.51395L6.16683 9.61114L14.8474 0.930612C15.1158 0.662127 15.5511 0.662127 15.8196 0.930612Z"
                                          fill="white"
                                       />
                                    </svg>
                                 ) : (
                                    <></>
                                 )}
                              </button>
                           )
                     })}
                     <button
                        onClick={toggleAnotherColors}
                        className="add-contact-form-color-item others"
                     >
                        <svg
                           width="22"
                           height="22"
                           viewBox="0 0 22 22"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.029 3.89575C11.4086 3.89625 11.716 4.20446 11.7156 4.58415L11.6987 17.4175C11.6982 17.7972 11.39 18.1046 11.0103 18.1041C10.6306 18.1036 10.3232 17.7954 10.3237 17.4157L10.3406 4.58235C10.341 4.20266 10.6493 3.89525 11.029 3.89575Z"
                              fill="white"
                           />
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M3.896 11C3.896 10.6203 4.2038 10.3125 4.5835 10.3125H17.4168C17.7965 10.3125 18.1043 10.6203 18.1043 11C18.1043 11.3797 17.7965 11.6875 17.4168 11.6875H4.5835C4.2038 11.6875 3.896 11.3797 3.896 11Z"
                              fill="white"
                           />
                        </svg>
                     </button>
                     {isAnotherColors ? (
                        <div className="add-contact-form-another-color">
                           <div className="add-contact-form-another-color-block">
                              {colors.map((color, index) => {
                                 if (index > 4)
                                    return (
                                       <button
                                          key={color.id}
                                          onClick={() => checkColor(color)}
                                          style={{
                                             backgroundColor: color.color,
                                          }}
                                          className="add-contact-form-another-color-item"
                                       >
                                          {color.isChecked ? (
                                             <svg
                                                width="17"
                                                height="12"
                                                viewBox="0 0 17 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                             >
                                                <path
                                                   fill-rule="evenodd"
                                                   clip-rule="evenodd"
                                                   d="M15.8196 0.930612C16.0881 1.1991 16.0881 1.6344 15.8196 1.90288L6.65297 11.0695C6.38448 11.338 5.94918 11.338 5.68069 11.0695L1.09736 6.48622C0.828875 6.21773 0.828875 5.78243 1.09736 5.51395C1.36585 5.24546 1.80115 5.24546 2.06963 5.51395L6.16683 9.61114L14.8474 0.930612C15.1158 0.662127 15.5511 0.662127 15.8196 0.930612Z"
                                                   fill="white"
                                                />
                                             </svg>
                                          ) : (
                                             <></>
                                          )}
                                       </button>
                                    )
                              })}
                           </div>
                           <div>
                              <button
                                 onClick={toggleAnotherColors}
                                 className="add-contact-form-another-color-submit-button"
                              >
                                 Подтвердить
                              </button>
                           </div>
                        </div>
                     ) : (
                        <></>
                     )}
                  </div>
                  {(statusFieldIsNotFilled || isColorNotChosen) && (
                     <WarningBlock
                        title={'Все поля обязательны для заполнения.'}
                     />
                  )}
                  {statusSymbolsIsNotEnough && (
                     <WarningBlock title={'Введите более 3 символов.'} />
                  )}
                  {/*{statusFieldIsNotFilled || isColorNotChosen ? (*/}
                  {/*   <div className="add-contact-form-control-warning">*/}
                  {/*      <svg*/}
                  {/*         className="warning-icon"*/}
                  {/*         viewBox="0 0 24 24"*/}
                  {/*         xmlns="http://www.w3.org/2000/svg"*/}
                  {/*      >*/}
                  {/*         <path d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V7.75C11.25 7.34 11.59 7 12 7C12.41 7 12.75 7.34 12.75 7.75V13C12.75 13.41 12.41 13.75 12 13.75Z" />*/}
                  {/*         <path d="M12 17.25C11.73 17.25 11.48 17.15 11.29 16.96C11.2 16.86 11.13 16.75 11.07 16.63C11.02 16.51 11 16.38 11 16.25C11 15.99 11.11 15.73 11.29 15.54C11.66 15.17 12.34 15.17 12.71 15.54C12.89 15.73 13 15.99 13 16.25C13 16.38 12.97 16.51 12.92 16.63C12.87 16.75 12.8 16.86 12.71 16.96C12.52 17.15 12.27 17.25 12 17.25Z" />*/}
                  {/*         <path d="M12.0002 22.75C11.3302 22.75 10.6502 22.58 10.0502 22.23L4.11017 18.8C2.91017 18.1 2.16016 16.81 2.16016 15.42V8.57999C2.16016 7.18999 2.91017 5.89999 4.11017 5.19999L10.0502 1.77C11.2502 1.07 12.7402 1.07 13.9502 1.77L19.8902 5.19999C21.0902 5.89999 21.8402 7.18999 21.8402 8.57999V15.42C21.8402 16.81 21.0902 18.1 19.8902 18.8L13.9502 22.23C13.3502 22.58 12.6702 22.75 12.0002 22.75ZM12.0002 2.74998C11.5902 2.74998 11.1702 2.85998 10.8002 3.06998L4.86017 6.49998C4.12017 6.92998 3.66016 7.71999 3.66016 8.57999V15.42C3.66016 16.27 4.12017 17.07 4.86017 17.5L10.8002 20.93C11.5402 21.36 12.4602 21.36 13.1902 20.93L19.1302 17.5C19.8702 17.07 20.3302 16.28 20.3302 15.42V8.57999C20.3302 7.72999 19.8702 6.92998 19.1302 6.49998L13.1902 3.06998C12.8302 2.85998 12.4102 2.74998 12.0002 2.74998Z" />*/}
                  {/*      </svg>*/}
                  {/*      <span className="add-contact-form-control-label-red">*/}
                  {/*         {' '}*/}
                  {/*         Все поля обязательны для заполнения.*/}
                  {/*      </span>*/}
                  {/*   </div>*/}
                  {/*) : (*/}
                  {/*   <></>*/}
                  {/*)}*/}
                  {/*{statusSymbolsIsNotEnough ? (*/}
                  {/*   <div className="add-contact-form-control-warning">*/}
                  {/*      <svg*/}
                  {/*         className="warning-icon"*/}
                  {/*         viewBox="0 0 24 24"*/}
                  {/*         xmlns="http://www.w3.org/2000/svg"*/}
                  {/*      >*/}
                  {/*         <path d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V7.75C11.25 7.34 11.59 7 12 7C12.41 7 12.75 7.34 12.75 7.75V13C12.75 13.41 12.41 13.75 12 13.75Z" />*/}
                  {/*         <path d="M12 17.25C11.73 17.25 11.48 17.15 11.29 16.96C11.2 16.86 11.13 16.75 11.07 16.63C11.02 16.51 11 16.38 11 16.25C11 15.99 11.11 15.73 11.29 15.54C11.66 15.17 12.34 15.17 12.71 15.54C12.89 15.73 13 15.99 13 16.25C13 16.38 12.97 16.51 12.92 16.63C12.87 16.75 12.8 16.86 12.71 16.96C12.52 17.15 12.27 17.25 12 17.25Z" />*/}
                  {/*         <path d="M12.0002 22.75C11.3302 22.75 10.6502 22.58 10.0502 22.23L4.11017 18.8C2.91017 18.1 2.16016 16.81 2.16016 15.42V8.57999C2.16016 7.18999 2.91017 5.89999 4.11017 5.19999L10.0502 1.77C11.2502 1.07 12.7402 1.07 13.9502 1.77L19.8902 5.19999C21.0902 5.89999 21.8402 7.18999 21.8402 8.57999V15.42C21.8402 16.81 21.0902 18.1 19.8902 18.8L13.9502 22.23C13.3502 22.58 12.6702 22.75 12.0002 22.75ZM12.0002 2.74998C11.5902 2.74998 11.1702 2.85998 10.8002 3.06998L4.86017 6.49998C4.12017 6.92998 3.66016 7.71999 3.66016 8.57999V15.42C3.66016 16.27 4.12017 17.07 4.86017 17.5L10.8002 20.93C11.5402 21.36 12.4602 21.36 13.1902 20.93L19.1302 17.5C19.8702 17.07 20.3302 16.28 20.3302 15.42V8.57999C20.3302 7.72999 19.8702 6.92998 19.1302 6.49998L13.1902 3.06998C12.8302 2.85998 12.4102 2.74998 12.0002 2.74998Z" />*/}
                  {/*      </svg>*/}
                  {/*      <span className="add-contact-form-control-label-red">*/}
                  {/*         {' '}*/}
                  {/*         Введите более 3 символов.*/}
                  {/*      </span>*/}
                  {/*   </div>*/}
                  {/*) : (*/}
                  {/*   <></>*/}
                  {/*)}*/}
               </div>
               <div className="add-contact-form-submit">
                  <button
                     className="add-contact-form-submit-button"
                     onClick={statusHandler}
                  >
                     {text}
                  </button>
               </div>
            </div>
         </div>
      </CSSTransition>
   )
}

export default StatusModal
