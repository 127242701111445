import { getNotificationDataByType } from "../utils"

class Notifications {
   static startGettingNotifications = () => {
      if (!("Notification" in window)) {
         console.log("Browser does not support notifications")
      } else {
         Notification.requestPermission()
      }
   }

   static createNotification = (data, type = 'message', closeTime = 3000) => {
      const { title, options } = getNotificationDataByType(data, type)
      const notification = new Notification(title, options)
      setTimeout(() => notification.close(), closeTime)
   }
}

export default Notifications