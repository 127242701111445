import React from 'react'

const ErrorContent = ({ head, message }) => {
   return (
      <div className="amo-login-error-wrap">
         <h2 className="amo-login-error-head">{head}</h2>
         <p className="amo-login-error-message">{message}</p>
      </div>
   )
}

export default ErrorContent
