import { CSSTransition } from 'react-transition-group'
import './warningModal.css'

const NotificationModal = ({ isVisible, title, text, ok }) => {
   return (
      <CSSTransition
         in={isVisible}
         classNames="warning"
         timeout={500}
         mountOnEnter
         unmountOnExit
      >
         <div className="warning">
            <div className="warning_wrapper">
               <span className="warning_title">{title}</span>
               <p className="warning_text">{text}</p>
               <div className="warning_buttons">
                  <button className="warning_button yes" onClick={ok}>
                     Ок
                  </button>
               </div>
            </div>
         </div>
      </CSSTransition>
   )
}

export default NotificationModal
