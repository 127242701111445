import React from 'react'

const SidebarContactCard = ({
   cardInfo,
   selectedConversation,
   onCardClick
}) => {
   const handleCardClick = () => {
      onCardClick(cardInfo)
   }

   const handleImageLoadingError = (e) => {
      e.target.src = '/assets/sidebar/default_avatar.png'
   }

   return (
      <div
         className={
            selectedConversation === cardInfo.dialog.id
               ? 'sidebar-contact-card-chosen'
               : 'sidebar-contact-card'
         }
         onClick={handleCardClick}
      >
         <div className="sidebar-card-profile-avatar">
            <img
               className="sidebar-card-profile-avatar-pic"
               src={
                  cardInfo.photo !== ''
                     ? cardInfo.photo
                     : `/assets/sidebar/default_avatar.png`
               }
               onError={handleImageLoadingError}
               alt=""
            />
            <img
               className="sidebar-card-profile-channel-icon"
               src={`/assets/sidebar/${cardInfo.domain}-icon.svg`}
               alt=""
            />
         </div>
         <div className="sidebar-card-conversation-details">
            <div className="sidebar-card-conversation-respondent">
               {cardInfo.name}
            </div>
         </div>
      </div>
   )
}

export default SidebarContactCard
