import React, { useState } from 'react'
import axios from 'axios'
import './dialogSettings.css'
import ReturnBtn from '../mainbar/ReturnBtn'
import { useDispatch, useSelector } from 'react-redux'
import { auth_selectUser } from '../../store/slices/auth/selectors'
import { updateFollowUpDateEnabled } from '../../store/slices/auth/thunk-creators'

const DialogSettings = ({
   autoReadStatus,
   setAutoReadStatus,
   isMainbarTablet,
   handleReturnBtnClick
}) => {
   const user = useSelector(auth_selectUser)
   const followUpDateEnabled = user.follow_up_date_enabled

   const [autoReadToggle, setAutoReadToggle] = useState(autoReadStatus)
   const [disableFollowUpDateToggle, setDisableFollowUpDateToggle] = useState(false)

   const dispatch = useDispatch()

   const handleAutoReadToggle = () => {
      setAutoReadToggle((prev) => !prev)
      let queryString = autoReadStatus ? 'false' : 'true'
      axios({
         method: 'post',
         url: `/api/v1/dialog/auto-read?status=${queryString}`,
         withCredentials: true,
      })
         .then(function (response) {
            //handle success
            if (response.data.error === false) {
               setAutoReadStatus(!autoReadStatus)
            } else {
               window.alert('Произошла ошибка!')
               setAutoReadToggle((prev) => !prev)
            }
         })
         .catch(function (response) {
            //handle error
            console.log(response)
            window.alert('Произошла ошибка!')
            setAutoReadToggle((prev) => !prev)
         })
   }

   const handleFollowUpDateToggle = async () => {
      setDisableFollowUpDateToggle(true)
      await dispatch(updateFollowUpDateEnabled({
         followUpDateEnabled: !followUpDateEnabled
      }))
      setDisableFollowUpDateToggle(false)
   }

   return (
      <div className="dialog-settings">
         <div className="dialog-settings-wrapper">
            <ReturnBtn
               isMainbarTablet={isMainbarTablet}
               onClick={handleReturnBtnClick}
            />
            <h2 className='dialog-settings__title'>
               Настройки диалога
            </h2>
            <ul className='dialog-settings__settings-list'>
               <li className='dialog-settings__settings-list-item'>
                  <div className='setting-item'>
                     <div
                        className={`setting-item__controller manager-permission-controller ${
                           !autoReadToggle 
                              ? 'setting-item__controller-toggle-btn_active' 
                              : ''
                        }`}
                        onClick={handleAutoReadToggle}
                     >
                        <div
                           className="setting-item__controller-toggle-btn manager-permission-toggle-button"
                        ></div>
                     </div>
                     <div className='setting-item__controller-info'>
                        <h4 className='setting-item__controller-name'>
                           Оставлять сообщения непрочитанными
                        </h4>
                        <p className='setting-item__controller-description'>
                           Это удобно в тех случаях, когда вы хотите вернуться к сообщению позже. Сообщение со статусом "непрочитанно" останется сверху в списке диалогов
                        </p>
                     </div>
                  </div>
               </li>
               <li className='dialog-settings__settings-list-item'>
                  <div className='setting-item'>
                     <button
                        className={`setting-item__controller manager-permission-controller ${
                           followUpDateEnabled 
                              ? 'setting-item__controller-toggle-btn_active' 
                              : ''
                        }`}
                        onClick={handleFollowUpDateToggle}
                        disabled={disableFollowUpDateToggle}
                     >
                        <div
                           className="setting-item__controller-toggle-btn manager-permission-toggle-button"
                        ></div>
                     </button>
                     <div className='setting-item__controller-info'>
                        <h4 className='setting-item__controller-name'>
                           Присвоение даты контакта
                        </h4>
                     </div>
                  </div>
               </li>
            </ul>
         </div>
      </div>
   )
}

export default DialogSettings
