const dateStatuses = [
   {
      name: 'Все',
      value: null
   },
   {
      name: 'Просрочено',
      value: 'overdue'
   },
   {
      name: 'Сегодня',
      value: 'today'
   },
   {
      name: 'Предстоящая',
      value: 'future'
   }
]

export default dateStatuses