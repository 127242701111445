import React, { useEffect, useState } from 'react'
import './Search.css'

const Search = ({ searchValue, onChange }) => {
   const [value, setValue] = useState(searchValue)

   const handleChange = (e) => {
      const value = e.target.value
      setValue(value)
      onChange(value)
   }

   useEffect(() => setValue(searchValue), [searchValue])

   return (
      <div className="sidebar-header-search-container">
         <div
            className="sidebar-header-search-field"
         >
            <img
               className="sidebar-header-search-field-icon"
               src={`/assets/sidebar/search.svg`}
               alt="Lookout"
            />
            <input
               className="sidebar-header-search-field-input"
               type="text"
               placeholder="Поиск"
               value={value}
               onChange={handleChange}
            />
         </div>
      </div>
   )
}

export default Search