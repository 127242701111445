import './constactDiscription.css'
import React, { useState } from 'react'
import NotificationContactModal from '../modals/NotificationContactModal'
import axios from 'axios'
import EditContact from '../modals/EditContact'
import ReturnBtn from './ReturnBtn'
import TagDropdown from '../TagDropdown'
import { getNormalizedDialog } from '../../utils'

const ContactDiscription = ({
   currentContact,
   setCurrentContact,
   setShowChat,
   dialogs,
   setDialogs,
   isMainbarTablet,
   handleReturnBtnClick,
   handleSetSelectedDialog
}) => {
   const [isShowDeleteContactModal, setShowDeleteContactModal] = useState(false)
   const [isShowArchiveContactModal, setShowArchiveContactModal] =
      useState(false)
   const [isShowEditContactModal, setShowEditContactModal] = useState(false)
   const toggleShowEditContactModal = () => {
      setShowEditContactModal(!isShowEditContactModal)
   }

   const handleContactDialogSelect = (dialog) => {      
      if (
         dialogs.find(
            (item) => item.dialog_id === dialog.id
         )
      ) {
         handleSetSelectedDialog(getNormalizedDialog(dialog))
         return setShowChat(true)
      } else {
         axios({
            method: 'get',
            url: `/api/v1/dialog/get?id=${dialog.id}`,
            withCredentials: true,
         })
            .then((response) => {
               const normalizedDialog = getNormalizedDialog(response.data.data.dialogs[0])

               setDialogs([
                  ...dialogs,
                  normalizedDialog
               ])
            })
            .then(() => setShowChat(true))
      }
   }

   const deleteContact = () => {
      axios({
         method: 'delete',
         url: `/api/v1/contact/delete?clientId=${currentContact.id}&channel=${currentContact.channel_id}`,
         withCredentials: true,
      }).then((response) => {
         if (response.data.error) {
            window.alert(response.data.data.error)
         } else {
            setShowDeleteContactModal(false)
            window.location.reload(false)
         }
      })
   }

   const archiveContact = () => {
      axios({
         method: 'put',
         url: `/api/v1/contact/archive?clientId=${currentContact.id}&channel=${currentContact.channel_id}`,
         withCredentials: true,
      }).then((response) => {
         if (response.data.error) {
            window.alert(response.data.data.error)
         } else {
            setShowArchiveContactModal(false)
            window.location.reload(false)
         }
      })
   }
   return (
      <>
         {currentContact !== null ? (
            <div className="contact-info">
               <EditContact
                  contact={currentContact}
                  setCurrentContact={setCurrentContact}
                  isVisible={isShowEditContactModal}
                  close={toggleShowEditContactModal}
               />
               <NotificationContactModal
                  isVisible={isShowDeleteContactModal}
                  title={'Уведомление'}
                  text={
                     'Удаление контакта предусматривает удаление диалога с ним без возможности впоследствии восстановить переписку.'
                  }
                  ok={deleteContact}
                  cancel={() => setShowDeleteContactModal(false)}
               />
               <NotificationContactModal
                  isVisible={isShowArchiveContactModal}
                  title={'Уведомление'}
                  text={
                     'Архивация контакта предусматривает удаление диалога с ним. Актуальная переписка будет снова доступна, если контакт напишет вам в будущем.'
                  }
                  ok={archiveContact}
                  cancel={() => setShowArchiveContactModal(false)}
               />
               <div className="contact-info-wrapper">
                  <ReturnBtn
                     isMainbarTablet={isMainbarTablet}
                     onClick={handleReturnBtnClick}
                  />
                  <div className="contact-info-header">
                     <div className="contact-info-header_wrapper">
                        <div className="contact-info-header_avatar">
                           <img
                              style={{ borderRadius: '50%' }}
                              width="48"
                              height="48"
                              src={
                                 currentContact.photo
                                    ? currentContact.photo
                                    : `/assets/sidebar/default_avatar.png`
                              }
                           />
                           <img
                              width="14"
                              height="14"
                              className="contact-info-header_avatar-icon"
                              src={`/assets/sidebar/${currentContact.domain}-icon.svg`}
                              alt=""
                           />
                        </div>
                        <div className="contact-info-header_block">
                           <span className="contact-info-header_block-name">
                              {currentContact.name}
                           </span>
                           {currentContact.dialogs.length > 1 ? (
                              <TagDropdown
                                 btnText='Написать сообщение'
                                 items={currentContact.dialogs}
                                 onSelect={handleContactDialogSelect}
                              />
                           ) : (
                              <button
                                 onClick={() => handleContactDialogSelect(currentContact.dialogs[0])}
                                 className="contact-info-header_block-go-to-dialogs"
                              >
                                 Написать сообщение
                              </button>
                           )}
                        </div>
                     </div>
                     <div className="contact-info-page-block">
                        <div className="manager-mainbar-page-button-active">
                           Информация о контакте
                        </div>
                     </div>
                  </div>
                  <hr></hr>
                  <div className="contact-info-control">
                     <div className="manager-mainbar-info-wrapper">
                        <div className="mainbar-add-manager-form">
                           <div className="mainbar-add-manager-control-form">
                              <label htmlFor="">Имя</label>
                              <input
                                 disabled
                                 type="text"
                                 value={currentContact.name}
                              />
                           </div>
                           <div className="mainbar-add-manager-control-form">
                              <label htmlFor="">Имя аккаунта</label>
                              <input
                                 disabled
                                 type="text"
                                 value={currentContact.username}
                              />
                           </div>
                           <div className="mainbar-add-manager-control-form">
                              <label htmlFor="">Телефон</label>
                              <input
                                 disabled
                                 type="text"
                                 value={
                                    currentContact.phone === null
                                       ? ''
                                       : currentContact.phone
                                 }
                              />
                           </div>
                        </div>
                        <div className="contact-button-block">
                           <button
                              onClick={toggleShowEditContactModal}
                              className="contact-edit-form-button"
                           >
                              <svg
                                 width="14"
                                 height="15"
                                 viewBox="0 0 14 15"
                                 fill="white"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M1.60449 12.75C1.60449 12.5084 1.80037 12.3125 2.04199 12.3125H12.542C12.7836 12.3125 12.9795 12.5084 12.9795 12.75C12.9795 12.9916 12.7836 13.1875 12.542 13.1875H2.04199C1.80037 13.1875 1.60449 12.9916 1.60449 12.75Z"
                                 />
                                 <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.244 1.8125C9.36009 1.81248 9.47143 1.8586 9.55351 1.94071L11.6841 4.07224C11.8549 4.24308 11.8549 4.51998 11.6841 4.69082L5.65162 10.726C5.56957 10.808 5.45826 10.8542 5.34219 10.8542H3.20801C2.96638 10.8542 2.77051 10.6583 2.77051 10.4167V8.2933C2.77051 8.17736 2.81653 8.06616 2.89846 7.98413L8.93453 1.94083C9.01658 1.85869 9.1279 1.81252 9.244 1.8125ZM9.2442 2.86897L3.64551 8.47437V9.97917H5.16092L10.7561 4.38153L9.2442 2.86897Z"
                                 />
                              </svg>
                              <span>Изменить</span>
                           </button>
                        </div>
                        <div className="contact-button-block">
                           <button
                              className="mainbar-delete-manager-form-button"
                              onClick={() => setShowDeleteContactModal(true)}
                           >
                              <svg
                                 fill="#FFFFFF"
                                 width="18"
                                 height="18"
                                 viewBox="0 0 18 18"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2.1875 3.41663C2.1875 3.175 2.38338 2.97913 2.625 2.97913H11.375C11.6166 2.97913 11.8125 3.175 11.8125 3.41663V13.3333C11.8125 13.5749 11.6166 13.7708 11.375 13.7708H2.625C2.38338 13.7708 2.1875 13.5749 2.1875 13.3333V3.41663ZM3.0625 3.85413V12.8958H10.9375V3.85413H3.0625Z"
                                 />
                                 <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M5.83301 5.89587C6.07463 5.89587 6.27051 6.09175 6.27051 6.33337V10.125C6.27051 10.3667 6.07463 10.5625 5.83301 10.5625C5.59138 10.5625 5.39551 10.3667 5.39551 10.125V6.33337C5.39551 6.09175 5.59138 5.89587 5.83301 5.89587Z"
                                 />
                                 <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M8.16699 5.89587C8.40862 5.89587 8.60449 6.09175 8.60449 6.33337V10.125C8.60449 10.3667 8.40862 10.5625 8.16699 10.5625C7.92537 10.5625 7.72949 10.3667 7.72949 10.125V6.33337C7.72949 6.09175 7.92537 5.89587 8.16699 5.89587Z"
                                 />
                                 <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0.729492 3.41663C0.729492 3.175 0.925368 2.97913 1.16699 2.97913H12.8337C13.0753 2.97913 13.2712 3.175 13.2712 3.41663C13.2712 3.65825 13.0753 3.85413 12.8337 3.85413H1.16699C0.925368 3.85413 0.729492 3.65825 0.729492 3.41663Z"
                                 />
                                 <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M5.24264 1.45633C5.31944 1.31622 5.46651 1.22913 5.62628 1.22913H8.39365C8.55475 1.22913 8.70283 1.31767 8.77906 1.4596L9.71908 3.2096C9.79191 3.34519 9.78814 3.50907 9.70915 3.64116C9.63016 3.77325 9.48757 3.85413 9.33366 3.85413H4.66699C4.51242 3.85413 4.36932 3.77256 4.29055 3.63956C4.21179 3.50656 4.20905 3.34187 4.28335 3.20633L5.24264 1.45633ZM5.88538 2.10413L5.40574 2.97913H8.60203L8.13203 2.10413H5.88538Z"
                                 />
                              </svg>
                              <span>Удалить контакт</span>
                           </button>
                           <button
                              onClick={() => setShowArchiveContactModal(true)}
                              className="contact-archive-form-button"
                           >
                              <svg
                                 width="18"
                                 height="18"
                                 viewBox="0 0 24 24"
                                 fill="#D94040"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M4 6.75C3.86193 6.75 3.75 6.86193 3.75 7V20C3.75 20.1381 3.86193 20.25 4 20.25H20C20.1381 20.25 20.25 20.1381 20.25 20V7C20.25 6.86193 20.1381 6.75 20 6.75H4ZM2.25 7C2.25 6.0335 3.0335 5.25 4 5.25H20C20.9665 5.25 21.75 6.0335 21.75 7V20C21.75 20.9665 20.9665 21.75 20 21.75H4C3.0335 21.75 2.25 20.9665 2.25 20V7Z"
                                 />
                                 <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M8.2249 12.0042C8.2249 11.5899 8.56069 11.2542 8.9749 11.2542H14.9749C15.3891 11.2542 15.7249 11.5899 15.7249 12.0042C15.7249 12.4184 15.3891 12.7542 14.9749 12.7542H8.9749C8.56069 12.7542 8.2249 12.4184 8.2249 12.0042Z"
                                 />
                                 <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M5.93556 2.00612C6.07798 1.84336 6.28372 1.75 6.5 1.75H17.5C17.7163 1.75 17.922 1.84336 18.0644 2.00612L21.5644 6.00612C21.8372 6.31785 21.8056 6.79167 21.4939 7.06443C21.1821 7.33719 20.7083 7.30561 20.4356 6.99388L17.1597 3.25H6.84032L3.56443 6.99388C3.29167 7.30561 2.81784 7.33719 2.50612 7.06443C2.19439 6.79167 2.1628 6.31785 2.43556 6.00612L5.93556 2.00612Z"
                                 />
                              </svg>
                              <span>Архивировать контакт</span>
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         ) : (
            <></>
         )}
      </>
   )
}

export default ContactDiscription
