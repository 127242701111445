import { INSTAGRAM, TELEGRAM, WHATSAPP } from "./consts"

// Возвращает текст для placeholder поля username в форме создания контакта
const getUsernameFieldPlaceholder = (channel) => {
   switch (channel) {
      case WHATSAPP:
         return '+7 (900) 000-00-00'
      case TELEGRAM:
         return 'Введите @логин или +7 (900) 000-00-00'
      case INSTAGRAM:
         return 'Введите логин'
      default:
         return 'Введите имя аккаунта'
   }
}

export default getUsernameFieldPlaceholder