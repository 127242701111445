import React, { useState } from 'react'
import {REVOKE_STATUS} from "../utils/helper";

const ErrorDesc = ({ message, messageStatus }) => {
   const [showErrors, setShowErrors] = useState(false)

   const returnMessageStatus = (status) => {
      switch (status) {
         case 0:
            return (
               <img
                  src={'/assets/chat/watch.svg'}
                  alt=""
                  style={{ width: '10px' }}
               />
            )
         case -1:
            return (
               <img
                  src={'/assets/chat/error.svg'}
                  alt=""
                  style={{ width: '10px' }}
               />
            )
         case 1:
            return (
               <svg className='message-status message-status_outgoing' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.9165 7.58398L5.24984 9.91732L11.0832 4.08398" stroke="#CCCFD0" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
            )
         case 2:
            return (
               <svg className='message-status message-status_outgoing' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.875 7.29102L3.25251 9.66851C3.3892 9.80524 3.6108 9.80524 3.74748 9.66851L5.25 8.16602" stroke="#CCCFD0" stroke-linecap="round"/>
                  <path d="M9.33333 4.08398L7 6.41732" stroke="#CCCFD0" stroke-linecap="round"/>
                  <path d="M4.0835 7.00065L6.75265 9.66981C6.88939 9.80654 7.11094 9.80654 7.24767 9.66981L12.8335 4.08398" stroke="#CCCFD0" stroke-linecap="round"/>
               </svg>
            )
         case 3:
            return (
               <svg className='message-status message-status_outgoing message-status_read' width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.875 7.29102L3.25251 9.66851C3.3892 9.80524 3.6108 9.80524 3.74748 9.66851L5.25 8.16602" stroke="#CCCFD0" stroke-linecap="round"/>
                  <path d="M9.33333 4.08398L7 6.41732" stroke="#CCCFD0" stroke-linecap="round"/>
                  <path d="M4.0835 7.00065L6.75265 9.66981C6.88939 9.80654 7.11094 9.80654 7.24767 9.66981L12.8335 4.08398" stroke="#CCCFD0" stroke-linecap="round"/>
               </svg>
            )
         case REVOKE_STATUS:
            return ''
         default:
            return
      }
   }
   return (
      <div
         style={{ display: 'flex' }}
         onMouseEnter={() => setShowErrors(true)}
         onMouseLeave={() => setShowErrors(false)}
      >
         {returnMessageStatus(messageStatus)}
         {message.error_description && showErrors && (
            <div
               className="mainbar-conversation-error-desc"
            >
               {message.error_description}
            </div>
         )}
      </div>
   )
}

export default ErrorDesc
