import { CSSTransition } from 'react-transition-group'
import './basicModal.css'
import React, { useEffect } from 'react'
import axios from 'axios'

const BasicModal = ({
   isVisible,
   cancel,
   title,
   text,
   buttonTitle,
   addedPhoto,
   setAddedPhoto,
   managerId,
}) => {
   const addPhoto = (e) => {
      console.log(e.target.files[0])
      setAddedPhoto(e.target.files[0])
   }
   useEffect(() => {
      if (addedPhoto !== null) {
         var bodyFormData = new FormData()
         bodyFormData.append('photo', addedPhoto)
         axios({
            method: 'post',
            url: `api/v1/manager/upload-photo?managerId=${managerId}`,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
         })
            .then(function (response) {
               if (response.data.error) {
                  window.alert(response.data.data.error)
               } else {
                  setAddedPhoto(null)
                  window.location.reload(false)
               }
            })
            .catch(function (err) {
               console.log(err)
               alert('Произошла ошибка...')
            })
      }
   }, [addedPhoto])
   return (
      <CSSTransition
         in={isVisible}
         classNames="basic"
         timeout={500}
         mountOnEnter
         unmountOnExit
      >
         <div className="basic">
            <div className="basic_wrapper">
               <div className="basic_header">
                  <span className="basic_title">{title}</span>
                  <div className="basic-close">
                     <button
                        className="basic-close-button"
                        onClick={() => {
                           cancel()
                           setAddedPhoto(null)
                        }}
                     >
                        <img src="/assets/controls/close.svg" alt="" />
                     </button>
                  </div>
               </div>

               <p className="basic_text">{text}</p>
               <div className="basic_buttons">
                  <label className="basic_button-label" htmlFor="add-photo">
                     {buttonTitle}
                  </label>
                  <input
                     id="add-photo"
                     type="file"
                     src={addedPhoto}
                     onChange={addPhoto}
                     className="basic_button"
                     accept="image/jpeg,image/png,image/gif"
                  />
               </div>
            </div>
         </div>
      </CSSTransition>
   )
}

export default BasicModal
